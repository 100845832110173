import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import TopbarMenuLogOut from './TopbarMenuLogOut';
import WizardApiCall from './WizardApiCall';
import { AUTHENTICATION } from '../../../constants';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      userName: '',
    };
  }

  async componentDidMount() {
    if (localStorage.getItem(AUTHENTICATION)) {
      const caller = new WizardApiCall();
      const z = await caller.getUserData();
      if (z.Error.code !== '0') {
        window.location = '/';
      }
      this.setState({ userName: `${z.Content.name} ${z.Content.surname}` });
    } else {
      window.location = '/';
    }
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse, userName } = this.state;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{userName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            { /* <TopbarMenuLink title="Wizard" icon="list" path="/pages/wizard" />
            <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" /> */ }
            <TopbarMenuLink title="Mi perfil" icon="user" path="/pages/profile" />
            <div className="topbar__menu-divider" />
            <TopbarMenuLogOut title="Cerrar Sesión" icon="exit" />
          </div>
        </Collapse>
      </div>
    );
  }
}
