import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar, Row,
} from 'reactstrap';
import swal from '@sweetalert/with-react';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import SimpleReactValidator from '../../simple-react-validator';
import ListItem from '../../../containers/NewCampaign/components/ListItem';
import PopupExchangeCenter from './PopupExchangeCenter';
import PopupExchangeUser from './PopupExchangeUser';
import WizardApiCall from '../../../containers/NewCampaign/components/WizardApiCall';

class PopupExchange extends PureComponent {
  static propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    zoneToModify: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalFlag: false,
      modalUserFlag: false,
      centerToModify: {},
      modifying: false,
      exchangeZones: [],
    };
    this.validator = new SimpleReactValidator({
      element: message => (
        <span className="spanMessageError">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    this.groupCentersList = this.groupCentersList.bind(this);
    this.modify = this.modify.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onOpenEditModal = this.onOpenEditModal.bind(this);
    this.onOpenUserModal = this.onOpenUserModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onCloseUserModal = this.onCloseUserModal.bind(this);
    this.handleChangeEntity = this.handleChangeEntity.bind(this);
    this.deleteCenterGroup = this.deleteCenterGroup.bind(this);
  }

  async componentDidMount() {
    this.groupCentersList();
  }

  onOpenModal() {
    this.setState({ modifying: false }, () => {
      this.setState({ centerToModify: {} });
      this.setState({ modalFlag: true });
    });
  }

  onOpenEditModal() {
    this.setState({ modalFlag: true });
  }

  onOpenUserModal() {
    this.setState({ modalUserFlag: true });
  }

  async onCloseModal() {
    this.setState({ modalFlag: false });
  }

  async onCloseUserModal() {
    this.setState({ modalUserFlag: false });
  }

  modify(id, name) {
    const { exchangeZones } = this.state;
    for (let i = 0; i < exchangeZones.length; i += 1) {
      if (exchangeZones[i].id === id) {
        this.setState({ centerToModify: exchangeZones[i] });
        this.setState({ modifying: true });
      }
    }
    console.log(name);
  }

  async deleteCenterGroup() { /* eslint-disable*/
    const { centerToModify } = this.state;
    swal({
      title: 'Eliminar grupo de centros de canje',
      text: '¿Está seguro que desea eliminar el grupo de centros de canje '+ centerToModify.name+'?',
      buttons: ['No', 'Si'],
    })
      .then(async (value)=> {
        if (value) {
          const caller = new WizardApiCall();
          await caller.deleteGroup(centerToModify.id);
          this.groupCentersList();
          this.setState({ centerToModify: {} });
          this.setState({ modifying: true });
        }});
  }

  async groupCentersList() {
    const { zoneToModify } = this.props;
    const caller = new WizardApiCall();
    let promise = {};
    try {
      promise = await caller.getGroupsFromZone(zoneToModify.zone.id);
    } catch (e) {
      console.log(e);
      return;
    }
    if (promise.Content) {
      const list = promise.Content;
      await list.sort((a, b) => (b.name < a.name ? 1 : -1));
      this.handleChangeEntity('exchangeZones', list);
      this.setState({ centerToModify: {} });
      this.setState({ modifying: false });
    }
  }

  handleChangeEntity(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const {
      onCloseModal, zoneToModify,
    } = this.props;
    const {
      modalFlag, modifying, centerToModify, modalUserFlag, exchangeZones,
    } = this.state;
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title divTitle">
                <h4 className="bold-text">Grupos de Centros de Canje</h4>
              </div>
              <form className="form form--horizontal">
                <Row>
                  <Col md={1} lg={1}>
                  </Col>
                  <Col md={6} lg={6}>
                    <div
                      className="boxGroupsCenters"
                      name="zones"
                      component="input"
                      type="text"
                    >
                      <div className="boxed-container">
                        {
                          exchangeZones.map((c, index) => (/* eslint-disable */
                            <ListItem
                              modify={this.modify}
                              key={index}
                              id={c.id}
                              name={c.name}
                              classSelected={centerToModify.id === c.id ? 'zoneSelected' : 'normalZone'}
                            />
                          ))
                        }
                      </div>
                    </div>
                  </Col>
                  <Col md={5} lg={5}>
                    <ButtonToolbar
                      className="form__button-toolbar wizard__toolbar buttonToolbarBotonera"
                    >
                      <Button
                        color="primary"
                        type="button"
                        className="buttonBotonera"
                        onClick={this.onOpenModal}
                      > Agregar Grupo
                      </Button>
                      <Button
                        disabled={!modifying}
                        color="primary"
                        type="button"
                        className="previous"
                        onClick={this.onOpenEditModal}
                        className="buttonBotonera"
                      > Editar Grupo
                      </Button>
                      <Button
                        disabled={!modifying}
                        color="primary"
                        type="button"
                        className="previous"
                        onClick={() => this.deleteCenterGroup()}
                        className="buttonBotonera"
                      > Eliminar Grupo
                      </Button>
                      <Button
                        disabled={!modifying}
                        color="primary"
                        type="button"
                        className="previous"
                        onClick={this.onOpenUserModal}
                        className="buttonBotonera"
                      > Asignar Usuarios
                      </Button>
                    </ButtonToolbar>
                    <Modal classNames={{ modal: 'modal_mapa' }} open={modalFlag} onClose={this.onCloseModal} center>
                      <PopupExchangeCenter
                        onCloseModal={this.onCloseModal}
                        zoneToModify={zoneToModify}
                        modifying={modifying}
                        selectedExchangeZone={centerToModify}
                        handleChangeEntity={this.groupCentersList}
                      />
                    </Modal>
                    <Modal open={modalUserFlag} onClose={this.onCloseUserModal} center>
                      <PopupExchangeUser
                        onCloseModal={this.onCloseUserModal}
                        zoneToModify={zoneToModify}
                        selectedExchangeZone={centerToModify}
                        handleChangeEntity={this.groupCentersList}
                      />
                    </Modal>
                  </Col>
                </Row>
                <ButtonToolbar className="form__button-toolbar buttonToolbarCancelNextSave">
                  <Button color="primary" type="button" onClick={onCloseModal}>Cerrar</Button>

                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up_exchange', // a unique identifier for this form
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(withTranslation('common')(PopupExchange));
