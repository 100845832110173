import React from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import PopUpUploadFiles from '../../../shared/components/form/PopupUploadFiles';

export default class UploadFilesForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleChangeFiles: PropTypes.func.isRequired,
    object: PropTypes.string,
    material: PropTypes.string,
    thumbnail: PropTypes.string,
    texture: PropTypes.string,
    vimage: PropTypes.string,
    captureSound: PropTypes.string,
  };

  static defaultProps = {
    object: '...',
    material: '...',
    thumbnail: '...',
    texture: '...',
    vimage: '...',
    captureSound: '...',
  };

  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const {
      handleSubmit, handleChangeFiles, material, captureSound, object, texture, thumbnail,
      vimage,
    } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Button
          style={{ width: '50%', padding: '6px' }}
          color="primary"
          type="button"
          className="next"
          onClick={this.onOpenModal}
        > Subir...
        </Button>
        <Modal open={open} onClose={this.onCloseModal} styles={{ modal: { maxWidth: '999px', width: '900px' } }} center>
          <PopUpUploadFiles
            object={object}
            material={material}
            texture={texture}
            vimage={vimage}
            thumbnail={thumbnail}
            captureSound={captureSound}
            onSubmit={this.onCloseModal}
            handleSubmit={handleSubmit}
            handleChangeFiles={handleChangeFiles}
          />
        </Modal>
      </div>
    );
  }
}
