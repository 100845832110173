import { React, PureComponent } from 'react';
import { CONTENT_TYPE, AUTHENTICATION_APP, AUTHENTICATION } from '../../../constants';

export default class WizardApiCallEdit extends PureComponent {
  static fetchData(options, endpoint) {
    return fetch(`${process.env.API_URL}${endpoint}`, options)
      .then(response => response.json())
      .then((json) => {
        if (json.Error.code !== '0' && json.Error.code !== '8') {
          console.log(endpoint);
          throw new Error();
        }
        return json;
      });
  }

  constructor(props) {
    super(props);
    this.state = {
      getCampaigns: '/campaigns',
      getZones: '/zones/campaigns/',
      getAudiences: '/zones/',
      getCoupon: '/coupons/',
      getCampaignStats: '/campaigns/stats',

    };
  }

  getCampaignStats(campaignId, dateRange, statType) {
    const {
      getCampaignStats,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCallEdit
      .fetchData(options, `${getCampaignStats}?campaigns=${campaignId}&range=${dateRange}&type=${statType}`);
  }

  getCampaigns() {
    const {
      getCampaigns,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCallEdit.fetchData(options, getCampaigns);
  }

  getCouponFromZone(zoneId) {
    const {
      getCoupon,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCallEdit.fetchData(options, `${getCoupon}zones/${zoneId}`);
  }

  getCoupon(couponId) {
    const {
      getCoupon,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCallEdit.fetchData(options, `${getCoupon}zones/${couponId}`);
  }

  getZones(campaignId) {
    const {
      getZones,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCallEdit.fetchData(options, `${getZones}${campaignId}`);
  }

  getAudience(zoneId) {
    const {
      getAudiences,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCallEdit.fetchData(options, `${getAudiences}${zoneId}/audiences`);
  }

  getRegions(zoneId) {
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    console.log(this);
    return WizardApiCallEdit.fetchData(options, `/zones/${zoneId}/regions`);
  }

  render() {
    return (
      <h3>H</h3>
    );
  }
}
