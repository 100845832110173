import { PureComponent } from 'react';
import { CONTENT_TYPE, AUTHENTICATION_APP, AUTHENTICATION } from '../../../constants';

export default class WizardApiCall extends PureComponent {
  static fetchData(options, endpoint) {
    return fetch(`${process.env.API_URL}${endpoint}`, options)
      .then(response => response.json())
      .then();
  }

  constructor(props) {
    super(props);
    this.state = {
      getUserData: '/users/me',
    };
  }

  getUserData() {
    const {
      getUserData,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, getUserData);
  }
}
