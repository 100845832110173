import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ListItem from './ListItem';
import WizardApiCallEdit from '../../EditionSide/components/WizardApiCallEdit';
import WizardApiCall from './WizardApiCall';

export default class WizardFormFour extends React.Component {
  static propTypes = {
    previousPage: PropTypes.func.isRequired,
    zoneId: PropTypes.number.isRequired,
    fromEditor: PropTypes.bool,
    nextPageToCoupon: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fromEditor: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      couponToModify: {},
      coupons: [
        { id: 0, name: '' },
      ],
      modifying: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    const { zoneId } = this.props;
    const caller = new WizardApiCallEdit();
    let promise = {};
    try {
      promise = await caller.getCouponFromZone(zoneId);
    } catch (e) {
      console.log(e);
      return;
    }
    if (promise.Content) {
      const list = promise.Content;
      await list.sort((a, b) => (b.announcementName < a.announcementName ? 1 : -1));
      this.setState({ coupons: list });
    } else {
      this.setState({ coupons: [{ id: 0, name: '' }] });
    }
  }

  async deleteCoupon(couponId) {
    const caller = new WizardApiCall();
    try {
      await caller.deleteCoupon(couponId);
    } catch (e) {
      console.log(e);
      return;
    }
    NotificationManager.success('El Anuncio se eliminó correctamente');
    this.componentDidMount();
  }

  handleClick(id, name) {
    const { coupons } = this.state;
    for (let i = 0; i < coupons.length; i += 1) {
      if (coupons[i].id === id) {
        this.setState({ couponToModify: coupons[i], modifying: true });
      }
    }
    console.log(name);
  }

  render() {
    const {
      previousPage, fromEditor, nextPageToCoupon,
    } = this.props;
    const {
      coupons, couponToModify, modifying,
    } = this.state;
    return (
      <form className="form form--horizontal wizard__form">
        <h3 className="wizard__title">Definición de Anuncios</h3>
        <div className="form__form-group">
          <div className="form__form-group-field boxed-group-field">
            <div
              className="boxed-zones"
              name="zones"
              component="input"
              type="text"
            >
              <div className="boxed-container">
                {
                  coupons
                    .map(
                      c => (
                        <ListItem
                          modify={this.handleClick}
                          key={c.id}
                          id={c.id}
                          name={c.name}
                          classSelected={couponToModify.id === c.id ? 'zoneSelected' : 'normalZone'}
                        />
                      ),
                    )
                }
              </div>
            </div>
            <ButtonToolbar
              className="form__button-toolbar wizard__toolbar toolbarCoupons"
            >
              <Button
                color="primary"
                type="button"
                className="previous btnEvent"
                onClick={() => nextPageToCoupon()}
              > Agregar Anuncio
              </Button>
              <Button
                disabled={!modifying}
                color="primary"
                type="button"
                className="previous btnEvent"
                onClick={() => nextPageToCoupon(couponToModify)}
              > Editar Anuncio
              </Button>
              <Button
                disabled={!modifying}
                color="primary"
                type="button"
                className="previous btnEvent"
                onClick={() => nextPageToCoupon(couponToModify, true)}
              > Duplicar Anuncio
              </Button>
              <Button
                disabled={!modifying}
                color="primary"
                type="button"
                onClick={() => this.deleteCoupon(couponToModify.id)}
                className="previous btnEvent"
              > Eliminar Anuncio
              </Button>
            </ButtonToolbar>
          </div>
          <ButtonToolbar className="form__button-toolbar wizard__toolbar">
            <Button color="primary" type="button" className="previous" onClick={previousPage}> Volver </Button>
            <Button
              color="primary"
              type="button"
              className="Continuar"
              disabled={fromEditor}
            >
              Continuar
            </Button>
          </ButtonToolbar>
          <NotificationContainer />
        </div>
      </form>
    );
  }
}
