import React, { PureComponent } from 'react';
import { Col, Card, Row } from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import PropTypes from 'prop-types';
import moment from 'moment';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import WizardFormFour from './WizardFormFour';

export default class WizardForm extends PureComponent {
  static propTypes = {
    selectedCampaign: PropTypes.objectOf(PropTypes.any),
    fromEditor: PropTypes.bool,
    title1: PropTypes.string,
    title2: PropTypes.string,
    title3: PropTypes.string,
    title4: PropTypes.string,
    returnFlag: PropTypes.bool,
  };

  static defaultProps = {
    selectedCampaign: {},
    fromEditor: false,
    // coupons: [],
    // getSelectedZone: {},
    title1: 'Paso 1',
    title2: 'Paso 2',
    title3: 'Paso 3',
    title4: 'Paso 4',
    returnFlag: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      campaignCreated: false,
      campaign: {},
      currentStep: 1,
      spinnerFlag: false,
      zoneId: 0,
      coupon: {},
      onFinishNavigateTo: '/pages/wizard',
      couponSpecificLocation: {},
      couponZoneLocation: [],
      flagFromEditor: false,
      duplicate: false,
    };
    if (props.fromEditor) {
      this.state.onFinishNavigateTo = '/pages/campaigns';
      const camp = {
        id: props.selectedCampaign.id,
        name: props.selectedCampaign.name,
        goalId: props.selectedCampaign.goalId,
        startDate: new Date(moment(this.getParsedDate(
          props.selectedCampaign.startDate,
        ), 'YYYY-MM-DD HH:mm:ss').toDate()),
        budget: props.selectedCampaign.budget,
      };
      this.state.campaignCreated = true;
      this.state.campaign = camp;
      this.state.flagFromEditor = props.fromEditor;
    }
    this.handleChange = this.handleChange.bind(this);
    this.nextPageToCoupon = this.nextPageToCoupon.bind(this);
    this.nextPageToCoupons = this.nextPageToCoupons.bind(this);
    this.handleChangeEntity = this.handleChangeEntity.bind(this);
  }

  getParsedDate(date) {
    const dateNew = String(date).split(' ');
    const days = String(dateNew[0]).split('/');
    console.log(this);
    return `${days[2]}-${days[1]}-${days[0]} ${dateNew[1]}`;
  }

  nextPage = () => {
    console.log('nextPage coupons being executed');
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
  };

  previousPage = () => {
    this.setState(prevState => ({ currentStep: prevState.currentStep - 1 }));
  };

  nextPageToCoupon(coupon, duplicate) {
    if (coupon) {
      this.setState({
        coupon,
        duplicate,
      });
    } else {
      this.setState({ coupon: {} });
    }
    this.nextPage();
  }

  nextPageToCoupons(selectedZoneId) {
    this.setState({ zoneId: selectedZoneId });
    this.nextPage();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChangeEntity(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const {
      currentStep, object, texture, vimage, captureSound, thumbnail, campaign, announcementName, sku, zoneId, coupon,
      amount, validity, promotionName, uniqueByUser, recaptureTime, entireDescription, termsAndCond, afterCampaign,
      campaignCreated, spinnerFlag, couponSpecificLocation, couponZoneLocation, duplicate,
    } = this.state;
    const {
      title1, title2, title3, title4, returnFlag, fromEditor,
    } = this.props;
    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="wizard">
              <div className="wizard__steps">
                <div className={`wizard__step${currentStep === 1 ? ' wizard__step--active' : ''}`}><p>{title1}</p></div>
                <div className={`wizard__step${currentStep === 2 ? ' wizard__step--active' : ''}`}><p>{title2}</p></div>
                <div
                  className={`wizard__step${currentStep === 3 ? ' wizard__step--active' : ''}`}
                >
                  <p>{title3}</p>
                </div>
                <div className={`wizard__step${currentStep === 4 ? ' wizard__step--active' : ''}`}>
                  <p>{title4}</p>
                </div>
              </div>
              <div className="wizard__form-wrapper">
                <Row>
                  <Col sm={0} md={1} lg={2} />
                  <Col sm={12} md={10} lg={8}>
                    {currentStep === 1
                    && (
                      <WizardFormOne
                        returnFlag={returnFlag}
                        campaign={campaign}
                        nextPage={this.nextPage}
                        handleChangeEntity={this.handleChangeEntity}
                        currentStep={currentStep}
                        campaignCreated={campaignCreated}
                      />
                    )
                    }
                    {currentStep === 2
                    && (
                      <WizardFormTwo
                        fromEditor={fromEditor}
                        onSubmit={this.nextPage}
                        nextPageToCoupons={this.nextPageToCoupons}
                        previousPage={this.previousPage}
                        handleChangeEntity={this.handleChangeEntity}
                        campaignId={String(campaign.id)}
                        couponZoneLocation={couponZoneLocation}
                      />
                    )}
                    {currentStep === 4
                    && (
                      <WizardFormThree
                        duplicate={duplicate}
                        selectedZoneId={zoneId}
                        coupon={coupon}
                        fromEditor={fromEditor}
                        spinnerFlag={spinnerFlag}
                        previousPage={this.previousPage}
                        handleChange={this.handleChange}
                        handleChangeEntity={this.handleChangeEntity}
                        object={object}
                        vimage={vimage}
                        texture={texture}
                        captureSound={captureSound}
                        thumbnail={thumbnail}
                        announcementName={announcementName}
                        sku={sku}
                        promotionName={promotionName}
                        amount={amount}
                        uniqueByUser={uniqueByUser}
                        recaptureTime={recaptureTime}
                        entireDescription={entireDescription}
                        termsAndCond={termsAndCond}
                        validity={validity}
                        afterCampaign={afterCampaign}
                        couponSpecificLocation={couponSpecificLocation}
                      />
                    )}
                    { currentStep === 3
                    && (
                      <WizardFormFour
                        nextPageToCoupon={this.nextPageToCoupon}
                        previousPage={this.previousPage}
                        zoneId={zoneId}
                        fromEditor={fromEditor}
                      />
                    ) }
                  </Col>
                </Row>
              </div>
              <NotificationContainer />
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}
