import React from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import PopupGeocercaMap from '../../GoogleApiMaps/PopupGeocercaMap';

export default class GeocercaForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    polygons: PropTypes.arrayOf(PropTypes.array).isRequired,
    handleChangePolygons: PropTypes.func.isRequired,
  };

  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const {
      handleSubmit, handleChange, polygons, handleChangePolygons,
    } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Button
          style={{ width: '50%', padding: '6px' }}
          color="primary"
          type="button"
          className="next"
          onClick={this.onOpenModal}
        > Definir...
        </Button>
        <Modal
          classNames={{
            modal: 'modal_mapa',
          }}
          open={open}
          onClose={this.onCloseModal}
          center
        >
          <PopupGeocercaMap
            handleChangePolygons={handleChangePolygons}
            polygons={polygons}
            onCloseModal={this.onCloseModal}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        </Modal>
      </div>
    );
  }
}
