import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PropTypes from 'prop-types';

export default class StatisticsCalendar extends React.Component {
  static propTypes = {
    handleChangeDate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleEvent = this.handleEvent.bind(this);
  }

  handleEvent(event, picker) {
    const { handleChangeDate } = this.props;
    let dateRange = 'lm';
    if (picker.chosenLabel === 'Mes Actual') {
      dateRange = 'cm';
    } else if (picker.chosenLabel === 'Ultimos 30 dias') {
      dateRange = 'cm';
    } else if (picker.chosenLabel === 'Ultima Semana') {
      dateRange = 'lw';
    } else { /* eslint-disable-next-line */
      dateRange = moment(picker.startDate).format('DD/MM/YYYY')+ '-' +moment(picker.endDate).format('DD/MM/YYYY');
    }
    $('#reportrange') /* eslint-disable-next-line */
      .html(moment(picker.startDate).format('DD/MM/YYYY')+ ' - ' +moment(picker.endDate).format('DD/MM/YYYY'));
    handleChangeDate(dateRange);
    $('#reportrange') /* eslint-disable-next-line */
      .html(moment(picker.startDate).format('DD/MM/YYYY')+ ' - ' +moment(picker.endDate).format('DD/MM/YYYY'));
  }

  render() {
    const start = moment().subtract(29, 'days');
    const end = moment();
    /* eslint-disable-next-line */
    const initialContent = moment(start).format('DD/MM/YYYY')+ ' - ' +moment(end).format('DD/MM/YYYY');
    const locale = {
      applyLabel: 'Aplicar',
      cancelLabel: 'Cancelar',
      customRangeLabel: 'Rango Definido',
      daysOfWeek: [
        'Do',
        'Lu',
        'Ma',
        'Mi',
        'Ju',
        'Vi',
        'Sa',
      ],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    };
    const selectionRange = {
      'Ultimos 30 dias': [moment().subtract(29, 'days'), moment()],
      'Mes Actual': [moment().startOf('month'), moment().endOf('month')],
      'Ultima Semana': [moment().subtract(6, 'days'), moment()],
    };
    return (
      <div className="daterange">
        <DateRangePicker
          locale={locale}
          onApply={this.handleEvent}
          ranges={selectionRange}
          startDate={start}
          endDate={end}
        >
          <FontAwesomeIcon icon={faCalendarAlt} />&nbsp;
          <span id="reportrange">{initialContent}</span> <FontAwesomeIcon icon={faCaretDown} />
        </DateRangePicker>
      </div>
    );
  }
}
