import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next/src/index';
import PropTypes from 'prop-types';
import GoogleMap from './GoogleApiWrapper';
import SimpleReactValidator from '../../shared/simple-react-validator';

class PopupGeocercaMap extends PureComponent {
  static propTypes = {
    polygons: PropTypes.arrayOf(PropTypes.any).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    handleChangePolygons: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      polygons: [],
      centers: [],
    };
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    if (props.polygons !== undefined) {
      this.state.polygons = props.polygons;
    }
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCenters = this.handleChangeCenters.bind(this);
    this.handleChangePolygons = this.handleChangePolygons.bind(this);
  }

  submitForm() {
    const { onCloseModal, handleChangePolygons } = this.props;
    const { centers } = this.state;
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
    if (centers.length !== 0) {
      this.setState(previousState => ({
        polygons: [...previousState.polygons, centers,
        ],
      }), () => {
        const { polygons } = this.state;
        handleChangePolygons('polygons', polygons);
      });
    }
    this.forceUpdate();
    onCloseModal();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChangePolygons(name, value) {
    this.setState({ [name]: value });
    const { handleChangePolygons } = this.props;
    const { polygons } = this.state;
    handleChangePolygons('polygons', polygons);
  }

  handleChangeCenters(name, value) {
    const centers = value.map(
      c => (
        { lat: c.latitude, lng: c.longitude }
      ),
    );
    this.setState({ [name]: centers });
  }

  render() {
    const {
      name, polygons,
    } = this.state;
    console.log(name);
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title" style={{ textAlign: 'center' }}>
                <h4 className="bold-text">Emplazar la ubicación y tamaño de la Geocerca</h4>
              </div>
              <form className="form form--horizontal">
                <div className="form__form-group">
                  <div className="form__form-group">
                    <div
                      className="boxed-map"
                      name="zones"
                      component="input"
                      type="text"
                    >
                      <GoogleMap
                        onePoint={false}
                        polygons={polygons}
                        onClick={e => this.onMapClick(e)}
                        submitForm={this.submitForm}
                        handleChangeCenters={this.handleChangeCenters}
                        handleChangePolygons={this.handleChangePolygons}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up_exchange_center', // a unique identifier for this form
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(withTranslation('common')(PopupGeocercaMap));
