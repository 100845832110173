import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AUTHENTICATION } from '../../../constants';

export default class TopbarMenuLogOut extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  };

  removeToken() {
    localStorage.removeItem(AUTHENTICATION);
    console.log(this);
  }

  render() {
    const { title, icon } = this.props;

    return (
      <Link className="topbar__link" to="/" onClick={this.removeToken}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">{title}</p>
      </Link>

    );
  }
}
