import {
  Card, CardBody, Col, Row, Table,
} from 'reactstrap';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class CampaignList extends PureComponent {
  static propTypes = {
    disableButton: PropTypes.func.isRequired,
    handleChangeCampaign: PropTypes.func.isRequired,
    selectedRow: PropTypes.number.isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.renderCampaign = this.renderCampaign.bind(this);
  }

  handleRowClick(campaign, index) {
    const { disableButton, handleChangeCampaign } = this.props;
    disableButton(false);
    handleChangeCampaign(campaign, index);
  }

  renderCampaign(campaign, index) {
    const { selectedRow } = this.props;
    let style = '';
    if (campaign.state === '0') {
      style = 'rowDisabledCampaign';
    }
    return (
      <tr
        style={{ background: '' }}
        key={index}
        data-item={campaign}
        className={selectedRow === index ? 'tableSelected' : style}
        onClick={() => this.handleRowClick(campaign, index)}
      >
        <td>
          {campaign.name}
        </td>
        <td>
          {campaign.startDate}
        </td>
        <td>
          -
        </td>
        <td>
          -
        </td>
        <td>
          {Number(campaign.budget)}
        </td>
        <td>
          -
        </td>
        <td>
          {campaign.goalId}
        </td>
        <td>
          -
        </td>
        <td>
          -
        </td>
        <td>
          -
        </td>
      </tr>
    );
  }

  render() {
    const { campaigns } = this.props;
    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <div className="wizard">
              <div className="wizard__form-wrapper" style={{ justifyContent: 'left' }}>
                <div>
                  <Col md={12} lg={12} xl={12}>
                    <Card>
                      <CardBody>
                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Inicio</th>
                              <th>Fin</th>
                              <th>Entrega</th>
                              <th>Presupuesto</th>
                              <th>Resultados</th>
                              <th>Alcance</th>
                              <th>Impresiones</th>
                              <th>CPR</th>
                              <th>Importe Gastado</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaigns.map(this.renderCampaign)}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}
