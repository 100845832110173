import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import ExamplePage from '../NewCampaign/index';
import ExamplePageTwo from '../Statistics/index';
import ExchangeSection from '../Exchange/index';
import EditionWizard from '../EditionSide';
import Announcement from '../Announcement';
import Zone from '../Zones';
import Profile from '../Profile';

const Pages = () => (
  <Switch>
    <Route path="/pages/profile" component={Profile} />
    <Route path="/pages/wizard" component={ExamplePage} />
    <Route path="/pages/statistics" component={ExamplePageTwo} />
    <Route path="/pages/campaigns" render={props => <EditionWizard {...props} isAuthed />} />
    <Route path="/pages/zones" render={props => <Zone {...props} isAuthed />} />
    <Route path="/pages/advertisement" render={props => <Announcement {...props} isAuthed />} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/pages" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route exact path="/exchange-center" component={ExchangeSection} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
