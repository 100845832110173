import React, { PureComponent } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker/es';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import ZoneForm from '../../../containers/NewCampaign/components/PopupjsAddZone';
import GeocercaForm from '../../../containers/NewCampaign/components/PopupjsGeocerca';
import AudienceForm from '../../../containers/NewCampaign/components/PopupjsAudience';
import SimpleReactValidator from '../../simple-react-validator';


class PopUp extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    createAudience: PropTypes.func.isRequired,
    createZone: PropTypes.func.isRequired,
    handleChangeEntity: PropTypes.func.isRequired,
    handleChangeEntityOnModify: PropTypes.func.isRequired,
    zoneToModify: PropTypes.objectOf(PropTypes.any).isRequired,
    modifyZone: PropTypes.func.isRequired,
    modifyAudience: PropTypes.func.isRequired,
    modifying: PropTypes.bool.isRequired,
    duplicate: PropTypes.bool.isRequired,
  };

  static getParsedDate(date) {
    const dateNew = String(date).split(' ');
    const days = String(dateNew[0]).split('/');
    return `${days[2]}-${days[1]}-${days[0]} ${dateNew[1]}`;
  }

  static decideGender(SexM, SexF, SexNB) {
    if (!SexM && !SexF && !SexNB) {
      return '';
    }
    return 1;
  }

  static existPolygons(Polygon) {
    if (Polygon.length === 0) {
      return '';
    }
    return 1;
  }

  static validateDate(Inicio, Fin) {
    const diffInDays = moment(Inicio).diff(moment(Fin), 'days');
    if (diffInDays > 0) {
      return '';
    }
    return 1;
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      zoneName: '',
      zoneStart: new Date(),
      zoneEnd: new Date(),
      // geocerca
      polygons: [],
      // audience
      publicName: '',
      publicSexM: false,
      publicSexF: false,
      publicSexNB: false,
      publicAgeFrom: {
        label: 'Select',
        value: 0,
      },
      publicAgeUpTo: {
        label: 'Select',
        value: 0,
      },
      publicLanguage: {
        label: 'Select',
        value: 0,
      },
      publicInterest: {
        label: 'Select',
        value: 0,
      },
      publicFrom: {
        label: 'Select',
        value: 0,
      },
      agesFrom: [],
      agesUpTo: [],
    };
    if (props.modifying) {
      this.state.zoneName = props.zoneToModify.zone.name;
      this.state.zoneStart = new Date(moment(PopUp.getParsedDate(props.zoneToModify.zone.startDate),
        'YYYY-MM-DD HH:mm:ss').toDate());
      this.state.zoneEnd = new Date(moment(PopUp.getParsedDate(
        props.zoneToModify.zone.endDate,
      ), 'YYYY-MM-DD HH:mm:ss').toDate());
      this.state.publicName = props.zoneToModify.audience.name;
      this.state.publicSexM = props.zoneToModify.audience.publicSexM;
      this.state.publicSexF = props.zoneToModify.audience.publicSexF;
      this.state.publicSexNB = props.zoneToModify.audience.publicSexNB;
      this.state.publicAgeFrom = props.zoneToModify.audience.olderThanAge;
      this.state.publicAgeUpTo = props.zoneToModify.audience.youngerThanAge;
      this.state.publicLanguage = {
        label: 'Select',
        value: 0,
      };
      this.state.publicInterest = {
        label: 'Select',
        value: 0,
      };
      this.state.publicFrom = {
        label: 'Select',
        value: 0,
      };
      this.state.polygons = props.zoneToModify.regions;
    }
    const { agesFrom, agesUpTo } = this.state;
    for (let i = 1; i <= 65; i += 1) {
      agesFrom.push({ label: `${i}`, value: i });
    }
    for (let i = 1; i <= 65; i += 1) {
      agesUpTo.push({ label: `${i}`, value: i });
    }
    agesUpTo[64].label = '65+';
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePolygons = this.handleChangePolygons.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.creationScenario = this.creationScenario.bind(this);
    this.modifyScenario = this.modifyScenario.bind(this);
    this.handleChangeAudiencePeople = this.handleChangeAudiencePeople.bind(this);
    this.handleChangeAudienceAge = this.handleChangeAudienceAge.bind(this);
    this.handleChangeAudienceLanguage = this.handleChangeAudienceLanguage.bind(this);
    this.handleChangeAudienceInterest = this.handleChangeAudienceInterest.bind(this);
  }

  async submitForm() {
    const {
      modifying, duplicate,
    } = this.props;

    this.validator.showMessages();
    this.forceUpdate();
    if (!modifying || (modifying && duplicate)) {
      this.creationScenario();
    } else {
      this.modifyScenario();
    }
  }

  async creationScenario() {
    const {
      onCloseModal, createAudience, createZone, handleChangeEntity,
    } = this.props;
    const {
      publicName, publicSexM, publicSexF, publicSexNB, publicAgeFrom, publicAgeUpTo, publicLanguage,
      publicInterest, zoneName, zoneStart, zoneEnd, publicFrom,
    } = this.state;
    if (!this.validator.allValid()) {
      return;
    }
    const audience = {
      id: 0,
      name: publicName,
      publicSexM,
      publicSexF,
      publicSexNB,
      olderThanAge: publicAgeFrom,
      youngerThanAge: publicAgeUpTo,
      genderId: ZoneForm.decideGender(publicSexM, publicSexF, publicSexNB),
      publicLanguage,
      publicInterest,
      publicFrom,
    };
    await handleChangeEntity('audience', audience);
    const zone = {
      id: 0,
      name: zoneName,
      startDate: zoneStart,
      endDate: zoneEnd,
      audienceId: 0,
      campaignId: 0,
    };
    await handleChangeEntity('zone', zone);
    onCloseModal();
    await createAudience();
    // End Creation Scenario

    createZone();
  }

  async modifyScenario() {
    const {
      publicName, publicSexM, publicSexF, publicSexNB, publicAgeFrom, publicAgeUpTo, publicLanguage,
      publicInterest, zoneName, zoneStart, zoneEnd, publicFrom,
    } = this.state;
    const {
      zoneToModify, handleChangeEntityOnModify, onCloseModal, modifyZone, modifyAudience,
    } = this.props;
    let modifyingZone = false;
    let modifyingAudience = false;
    const { polygons } = this.state;
    const zoneOld = {
      name: zoneToModify.zone.name,
      startDate: moment(new Date(PopUp.getParsedDate(zoneToModify.zone.startDate))).format('YYYY-MM-DD'),
      endDate: moment(new Date(PopUp.getParsedDate(zoneToModify.zone.endDate))).format('YYYY-MM-DD'),
    };
    const zoneEdited = {
      name: zoneName,
      startDate: moment(zoneStart).format('YYYY-MM-DD'),
      endDate: moment(zoneEnd).format('YYYY-MM-DD'),
    };
    if (!this.validator.allValid()) {
      return;
    }
    if (!isEqual(zoneOld, zoneEdited) || !isEqual(zoneToModify.regions, polygons)) {
      modifyingZone = true;
    }
    if (
      zoneToModify.audience.name !== publicName
      || zoneToModify.audience.publicSexM !== publicSexM
      || zoneToModify.audience.publicSexF !== publicSexF
      || zoneToModify.audience.publicSexNB !== publicSexNB
      || zoneToModify.audience.olderThanAge !== publicAgeFrom
      || zoneToModify.audience.youngerThanAge !== publicAgeUpTo
    ) {
      modifyingAudience = true;
    }
    if (modifyingZone) {
      const zn = {
        id: zoneToModify.zone.id,
        name: zoneName,
        startDate: zoneStart,
        endDate: zoneEnd,
      };
      modifyZone(zn, polygons);
      handleChangeEntityOnModify('zone', zn);
    }
    if (modifyingAudience) {
      const aud = {
        id: zoneToModify.audience.id,
        name: publicName,
        genderId: ZoneForm.decideGender(publicSexM, publicSexF, publicSexNB),
        publicSexM,
        publicSexF,
        publicSexNB,
        publicFrom,
        olderThanAge: String(publicAgeFrom.value),
        youngerThanAge: String(publicAgeUpTo.value),
        publicLanguage,
        publicInterest,
      };
      modifyAudience(aud);
      aud.olderThanAge = { label: aud.olderThanAge, value: aud.olderThanAge };
      aud.youngerThanAge = { label: aud.youngerThanAge, value: aud.youngerThanAge };
      handleChangeEntityOnModify('audience', aud);
    }
    onCloseModal();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChangeCheckBox(selector, value) {
    this.setState({ [selector]: value });
  }

  handleChangeStartDate(selected) {
    this.setState({ zoneStart: selected });
  }

  handleChangeEndDate(selected) {
    this.setState({ zoneEnd: selected });
  }

  handleChangeAudienceLanguage(selected) {
    this.setState({ publicLanguage: { value: selected.value, label: selected.label } });
  }

  handleChangeAudiencePeople(selected) {
    this.setState({ publicFrom: { value: selected.value, label: selected.label } });
  }

  handleChangeAudienceInterest(selected) {
    this.setState({ publicInterest: { value: selected.value, label: selected.label } });
  }

  handleChangeAudienceAge(selector, selected) {
    this.setState({ [selector]: { value: selected.value, label: selected.label } });
  }

  handleChangePolygons(name, value) {
    const { handleChangeEntity } = this.props;
    this.setState({ [name]: value });
    handleChangeEntity(name, value);
  }

  render() {
    const {
      handleSubmit, onCloseModal, t, zoneToModify,
    } = this.props;
    console.log(zoneToModify);
    const {
      zoneName, zoneStart, zoneEnd, publicName, publicSexM, publicSexF, publicSexNB, publicAgeFrom,
      publicAgeUpTo, publicLanguage, publicInterest, publicFrom, agesFrom, agesUpTo, polygons,
    } = this.state;
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title" style={{ textAlign: 'center' }}>
                <h5 className="bold-text">{t('Crear Zona')}</h5>
                <h5 className="subhead">Complete todos los campos</h5>
              </div>
              <form className="form form--horizontal">
                <Row>
                  <Col sm={0} md={3} />
                  <Col sm={12} md={9}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Nombre de la Zona</span>
                      <div className="form__form-group-field">
                        <input
                          name="zoneName"
                          component="input"
                          type="text"
                          placeholder="Nombre de la zona"
                          style={{ width: '50%' }}
                          onChange={this.handleChange}
                          value={zoneName}
                        />
                      </div>
                      <div className="form__form-group-field">
                        {this.validator.message('nombre', zoneName, 'required')}
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Geocerca</span>
                      <div className="form__form-group-field">
                        <GeocercaForm
                          polygons={polygons}
                          handleSubmit={handleSubmit}
                          handleChange={this.handleChange}
                          handleChangePolygons={(n, e) => this.handleChangePolygons(n, e)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Fecha de Inicio de la Zona</span>
                      <div className="form__form-group-field">
                        <DatePicker
                          name="zoneStart"
                          type="text"
                          selected={zoneStart}
                          onChange={this.handleChangeStartDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Fecha de Fin de la Zona</span>
                      <div className="form__form-group-field">
                        <DatePicker
                          name="zoneEnd"
                          type="text"
                          component="input"
                          selected={zoneEnd}
                          onChange={this.handleChangeEndDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        <div className="form__form-group-icon">
                          <CalendarBlankIcon />
                        </div>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Entrega por Horarios</span>
                      <div className="form__form-group-field">
                        <Button
                          disabled
                          color="primary"
                          type="submit"
                          className="next"
                          style={{ width: '50%', marginBottom: '0px', padding: '6px' }}
                        >
                          Definir...
                        </Button>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Público</span>
                      <div className="form__form-group-field">
                        <AudienceForm
                          agesFrom={agesFrom}
                          agesUpTo={agesUpTo}
                          handleChangeAudiencePeople={this.handleChangeAudiencePeople}
                          publicName={publicName}
                          publicFrom={publicFrom}
                          publicAgeFrom={publicAgeFrom}
                          publicAgeUpTo={publicAgeUpTo}
                          publicSexM={publicSexM}
                          publicSexF={publicSexF}
                          publicSexNB={publicSexNB}
                          publicLanguage={publicLanguage}
                          publicInterest={publicInterest}
                          handleSubmit={handleSubmit}
                          handleChange={this.handleChange}
                          handleChangeCheckBox={this.handleChangeCheckBox}
                          handleChangeAudienceInterest={this.handleChangeAudienceInterest}
                          handleChangeAudienceLanguage={this.handleChangeAudienceLanguage}
                          handleChangeAudienceAge={this.handleChangeAudienceAge}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <ButtonToolbar className="form__button-toolbar" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <Button type="button" onClick={onCloseModal}>
                    Cancelar
                  </Button>
                  <Button color="primary" type="button" onClick={this.submitForm}>Guardar</Button>
                </ButtonToolbar>
                <div className="form__form-group-field">
                  {this.validator.message('fecha de inicio', zoneStart, 'required')}
                </div>
                <div className="form__form-group-field">
                  {this.validator.message('fecha de fin', zoneEnd, 'required')}
                </div>
                <div className="form__form-group-field">
                  {this
                    .validator
                    .message('fecha de fin superior igual a fecha inicio ', PopUp
                      .validateDate(zoneStart, zoneEnd), 'required')}
                </div>
                <div className="form__form-group-field">
                  {this.validator.message('geocerca', PopUp.existPolygons(polygons), 'required')}
                </div>
                <div className="form__form-group-field">
                  {this.validator.message('nombre de audiencia', publicName, 'required')}
                </div>
                <div className="form__form-group-field">
                  {this
                    .validator
                    .message('sexo de audiencia', PopUp.decideGender(publicSexM, publicSexF, publicSexNB), 'required')}
                </div>
                <div className="form__form-group-field">
                  {this.validator.message('edad desde', publicAgeFrom, 'required')}
                </div>
                <div className="form__form-group-field">
                  {this.validator.message('edad hasta', publicAgeUpTo, 'required')}
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up', // a unique identifier for this form
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(withTranslation('common')(PopUp));
