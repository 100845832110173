import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import GoogleMap from '../../../containers/GoogleApiMaps/GroupSpecificLocation/GoogleGroupLocation';
import SimpleReactValidator from '../../simple-react-validator';
import WizardApiCall from '../../../containers/NewCampaign/components/WizardApiCall';

class PopupExchangeCenter extends PureComponent {
  static propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    modifying: PropTypes.bool.isRequired,
    zoneToModify: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedExchangeZone: PropTypes.objectOf(PropTypes.any).isRequired,
    handleChangeEntity: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      centers: [],
    };
    if (props.modifying) {
      this.state.name = props.selectedExchangeZone.name;
      this.state.centers = props.selectedExchangeZone.exchangeCenters;
    }
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.modifyScenario = this.modifyScenario.bind(this);
    this.creationScenario = this.creationScenario.bind(this);
    this.handleChangeCenters = this.handleChangeCenters.bind(this);
  }

  submitForm() {
    const { onCloseModal, modifying } = this.props;
    const { centers } = this.state;
    this.validator.showMessages();
    this.forceUpdate();
    if (this.validator.allValid()) {
      onCloseModal();
    }
    const centersToAPI = [];
    for (let i = 0; i < centers.length; i += 1) {
      centersToAPI.push({ latitude: centers[i].latitude, longitude: centers[i].longitude });
    }
    if (!modifying) {
      this.creationScenario(centersToAPI);
    } else {
      this.modifyScenario(centersToAPI);
    }
  }

  async creationScenario(centersToAPI) {
    const {
      onCloseModal, zoneToModify, handleChangeEntity,
    } = this.props;
    const {
      name,
    } = this.state;
    if (this.validator.allValid()) {
      const exchangeZone = {
        id: 0,
        name,
        exchangeCenters: centersToAPI,
      };
      const caller = new WizardApiCall();
      await caller.createGroup(zoneToModify.zone.id, exchangeZone);
      handleChangeEntity();
      onCloseModal();
      // End Creation Scenario
    }
  }

  async modifyScenario() {
    const {
      name, centers,
    } = this.state;
    const {
      onCloseModal, selectedExchangeZone, zoneToModify, handleChangeEntity,
    } = this.props;
    const group = {
      id: parseInt(selectedExchangeZone.id, 10),
      name,
      exchangeCenters: centers,
    };
    if (!isEqual(selectedExchangeZone, group)) {
      const caller = new WizardApiCall();
      await caller.modifyGroup(zoneToModify.zone.id, group);
      handleChangeEntity();
    }
    onCloseModal();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChangeCenters(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const { onCloseModal } = this.props;
    const {
      name, centers,
    } = this.state;
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title" style={{ textAlign: 'center' }}>
                <h4 className="bold-text">Nuevo Grupo de Centros de Canje</h4>
              </div>
              <form className="form form--horizontal">
                <div className="form__form-group">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Nombre del Grupo</span>
                    <div className="form__form-group-field">
                      <input
                        style={{ width: '300px' }}
                        name="name"
                        component="input"
                        type="text"
                        placeholder="Nombre del grupo de centros de canje"
                        onChange={this.handleChange}
                        value={name || ''}
                      />
                    </div>
                    <div className="form__form-group-field">
                      {this.validator.message('nombre', name, 'required')}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div
                      className="boxed-map"
                      name="zones"
                      component="input"
                      type="text"
                    >
                      <GoogleMap
                        onePoint={false}
                        centers={centers}
                        onClick={e => this.onMapClick(e)}
                        submitForm={this.submitForm}
                        onCloseModal={onCloseModal}
                        handleChangeCenters={this.handleChangeCenters}
                      />
                    </div>
                  </div>
                </div>

              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up_exchange_center', // a unique identifier for this form
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(withTranslation('common')(PopupExchangeCenter));
