import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, ButtonToolbar,
} from 'reactstrap';
import swal from '@sweetalert/with-react';
import Geosuggest from 'react-geosuggest';
import {
  Map, GoogleApiWrapper, Marker, Polygon,
} from 'google-maps-react';

const mapStyles = {
  width: '798px',
  height: '366px',
};

const bottomStyles = {
  top: '410px',
};

class MapContainer extends PureComponent {
  static propTypes = {
    polygons: PropTypes.arrayOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
    handleChangePolygons: PropTypes.func.isRequired,
    handleChangeCenters: PropTypes.func.isRequired,
    onePoint: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      centers: [],
      polygons: [],
      markerFlag: false,
      buttonFlag: true,
      pointFromSearchInput: { latitude: -31.65464054, longitude: -64.67023386 },
    };
    if (props.polygons !== undefined) {
      this.state.polygons = props.polygons;
    }
    if (props.polygons && props.polygons.length === 1) {
      this.state.pointFromSearchInput = {
        latitude: props.polygons[0][0].lat,
        longitude: props.polygons[0][0].lng,
      };
    }
    this.setStateForCentersClick = this.setStateForCentersClick.bind(this);
    this.onPolygonToModifyClick = this.onPolygonToModifyClick.bind(this);
    this.removePointFromArrayPolygons = this.removePointFromArrayPolygons.bind(this);
    this.removePointFromArray = this.removePointFromArray.bind(this);
    this.onInputOptionClick = this.onInputOptionClick.bind(this);
    this.setStateForCenters = this.setStateForCenters.bind(this);
    this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
    this.onMarkerDragEndPolygons = this.onMarkerDragEndPolygons.bind(this);
    this.displayMarkersPolygons = this.displayMarkersPolygons.bind(this);
    this.displayMarkers = this.displayMarkers.bind(this);
    this.onPolygonClick = this.onPolygonClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.submitMap = this.submitMap.bind(this);
    this.removeCenters = this.removeCenters.bind(this);
    this.removePolygons = this.removePolygons.bind(this);
  }

  onMapClick(t, map, coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setStateForCentersClick(lat, lng);
  }

  onPolygonToModifyClick(polygon, index) {
    const { centers } = this.state;
    if (centers.length !== 0) {
      this.onPolygonClick();
    }
    const { polygons } = this.state;
    const p = [];
    polygons.map((item, i) => { if (i !== index) { p.push(item); } return false; });
    this.setState({
      polygons: p,
    });
    const centersPolygon = polygon.paths.map(ll => ({ latitude: ll.lat, longitude: ll.lng }));
    this.setState({ centers: centersPolygon });
    if (centersPolygon.paths === undefined) {
      this.setState({ buttonFlag: false });
    } else {
      this.setState({ buttonFlag: true });
    }
  }

  onInputOptionClick(suggest) {
    if (suggest !== undefined) {
      this.setStateForCenters(suggest);
    }
  }

  onMarkerDragEnd(t, coord, index) {
    const { centers } = this.state;
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState(() => { centers[index] = { latitude: lat, longitude: lng }; });
    // this.setStateForCentersClick(lat, lng);
    this.forceUpdate();
  }

  onMarkerDragEndPolygons(t, coord, index) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const { polygons: polygon } = this.state;
    this.setState(() => { polygon[index] = [{ lat, lng }]; }, () => {
      this.forceUpdate();
    });
  }

  onPolygonClick(event) {
    const { centers } = this.state;
    const centersPolygon = centers.map(ll => ({ lat: ll.latitude, lng: ll.longitude }));
    this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
    this.setState(previousState => ({
      polygons: [...previousState.polygons, centersPolygon,
      ],
    }));
    this.setState({ centers: [] });
    console.log(event);
    this.setState({ buttonFlag: true });
  }

  setStateForCenters(suggest) {
    const { onePoint } = this.props;
    const { centers } = this.state;
    if (!onePoint || (onePoint && centers.length === 0)) {
      this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
      this.setState(previousState => ({
        centers: [...previousState.centers, { latitude: suggest.location.lat, longitude: suggest.location.lng },
        ],
      }));
    }
    if (onePoint && centers.length === 1) {
      this.setState(() => ({
        centers: [{ latitude: suggest.location.lat, longitude: suggest.location.lng }],
      }));
    }
    this.setState(() => ({
      pointFromSearchInput: { latitude: suggest.location.lat, longitude: suggest.location.lng },
    }));
  }

  setStateForCentersClick(lat, lng) {
    const { onePoint } = this.props;
    const { centers } = this.state;
    if (!onePoint || (onePoint && centers.length === 0)) {
      this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
      this.setState(previousState => ({
        centers: [...previousState.centers, { latitude: lat, longitude: lng },
        ],
      }));
      this.setState({ buttonFlag: false });
    }
    if (onePoint && centers.length === 1) {
      this.setState(() => ({
        centers: [{ latitude: lat, longitude: lng }],
      }));
      this.setState({ buttonFlag: false });
    }
    this.setState(() => ({
      pointFromSearchInput: { latitude: lat, longitude: lng },
    }));
  }

  displayMarkers = () => { /* eslint-disable*/
    const { centers, markerFlag } = this.state;
    return centers.map((store, index) => {
      return <Marker
        hide={markerFlag}
        key={index}
        id={index}
        position={{
        lat: store.latitude,
        lng: store.longitude
      }}
        draggable
        onDragend={(t, map, coord) => this.onMarkerDragEnd(t, coord, index)}
        onClick={this.removePointFromArray} />
    })
  }

  displayMarkersPolygons = () => {
    const { polygons, markerFlag } = this.state;
    let i;
    let list = [];
    for (i = 0; i < polygons.length; i += 1){
      if (polygons[i].length === 1) {
        list.push({ polygons: polygons[i][0], index: i});
      }
    }
    return list.map((store, index) => {
      return <Marker
        hide={markerFlag}
        key={index}
        id={index}
        position={{
        lat: store.polygons.lat,
        lng: store.polygons.lng
      }}
        draggable
        onDragend={(t, map, coord) => this.onMarkerDragEndPolygons(t, coord, store.index)}
        onClick={this.removePointFromArrayPolygons} />
    })
  }

  async submitMap() {
    const { submitForm } = this.props;
    const { polygons, centers } = this.state;
    const { handleChangeCenters } = this.props;
    await handleChangeCenters('centers', centers);
    const { handleChangePolygons } = this.props;
    await handleChangePolygons('polygons', polygons);
    submitForm();
  }

  removePointFromArray(pointToRemove) {
    const { centers } = this.state;
    let centersAux=[];
    for (let i = 0; i < centers.length; i += 1) {
      if (centers[i].latitude !== pointToRemove.position.lat || centers[i].longitude !== pointToRemove.position.lng) {
        centersAux.push(centers[i]);
      }
    }
    this.setState({ centers: centersAux });
    this.setState({ markerFlag: true });
    this.displayMarkers();
    const { markerFlag } = this.state;
    this.setState({ markerFlag: false }, () => {
      this.displayMarkers();
    });
  }

  removeCenters() {
    const { centers } = this.state;
    this.setState({ centers: [] });
    this.setState({ markerFlag: true });
    this.setState({ buttonFlag: true });
    this.displayMarkers();
    const { markerFlag } = this.state;
    this.setState({ markerFlag: false }, () => {
      this.displayMarkers();
    });
  }

  removePolygons() {
    swal({
      title: "Eliminar todas las geocercas",
      text: "¿Está seguro que desea eliminar todas las geocercas?",
      buttons: ["No","Si"]
    })
    .then((value)=> {
      if (value) {
        const { polygons } = this.state;
        this.setState({ polygons: [] });
        this.setState({ centers: [] });
        this.setState({ markerFlag: true });
        this.setState({ buttonFlag: true });
        this.displayMarkers();
        const { markerFlag } = this.state;
        this.setState({ markerFlag: false }, () => {
          this.displayMarkers();
        });
      }});
  }

  removePointFromArrayPolygons(pointToRemove) {
    const { polygons } = this.state;
    let polygonsAux = [];
    for (let i = 0; i < polygons.length; i += 1){
      if (polygons[i].length !== 1
        || polygons[i][0].lat !== pointToRemove.position.lat
        || polygons[i][0].lng !== pointToRemove.position.lng ) {
        polygonsAux.push(polygons[i]);
      }
    }
    this.setState({ polygons: polygonsAux });
    this.setState({ markerFlag: true });
    this.displayMarkers();
    const { markerFlag } = this.state;
    this.setState({ markerFlag: false }, () => {
      this.displayMarkers();
    });
  }

  render() {
    const { polygons, buttonFlag } = this.state;
    const {
      pointFromSearchInput, centers,
    } = this.state;
    const centersPolygon = centers.map( ll => { return { lat: ll.latitude, lng: ll.longitude }});
    return (
      <div>
        <div>
          <Geosuggest
            style={{
              'input': { maxHeight: '100px' },
              'suggests': { backgroundColor: 'white', zIndex: '99999', position: 'absolute', width: '398px' },
              'suggestItem': {} }}
            placeholder="Buscar lugares..."
            onSuggestSelect={this.onInputOptionClick}
          />
        </div>
        <div>
          <Map /* eslint-disable-next-line */
            google={this.props.google}
            zoom={8}
            style={mapStyles}
            initialCenter={{ lat: pointFromSearchInput.latitude, lng: pointFromSearchInput.longitude }}
            onClick={this.onMapClick}
          >
            {this.displayMarkers()}
            <Polygon
              paths={centersPolygon}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="#ff0000"
              fillOpacity={0.35}
              onClick={ (event) => this.onPolygonClick(event) }
            />
            {polygons
              .map(
                (polygon, index) => (
                  <Polygon
                    key={index}
                    paths={polygon}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor="#0000FF"
                    fillOpacity={0.35}
                    onClick={ (polygon) => this.onPolygonToModifyClick(polygon, index) }
                  />
                ),
              )
            }
            {this.displayMarkersPolygons()}
          </Map>
          <Row>
            <Col sm={12} md={10} lg={10} style={bottomStyles}>
              <ButtonToolbar className="form__button-toolbar btn-left">
                <Button color="primary" type="button" disabled={buttonFlag} onClick={this.removeCenters}>Eliminar geocerca</Button>
                <Button color="primary" type="button" onClick={this.removePolygons}>Eliminar todas las geocercas</Button>
              </ButtonToolbar>
            </Col>
            <Col sm={12} md={2} lg={2} style={bottomStyles}>
              <ButtonToolbar className="form__button-toolbar btn-center">
                <Button color="primary" type="button" onClick={this.submitMap}>Guardar</Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.MAP_API_KEY,
})(MapContainer);
