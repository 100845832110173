import React, { PureComponent } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SimpleReactValidator from '../../simple-react-validator';
import WizardApiCall from '../../../containers/NewCampaign/components/WizardApiCall';


class PopupExchangeUser extends PureComponent {
  static propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    userToModify: PropTypes.objectOf(PropTypes.any).isRequired,
    handleChangeEntity: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    console.log(props.userToModify);
    if (Object.keys(props.userToModify).length) {
      this.state = {
        userId: props.userToModify.id,
        userName: props.userToModify.userName,
      };
    } else {
      this.state = {
        userId: '',
        userName: '',
      };
    }
    this.validator = new SimpleReactValidator({
      element: message => (
        <span className="spanMessageError">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    this.handleChange = this.handleChange.bind(this);
    this.addUsers = this.addUsers.bind(this);
    this.existUserName = this.existUserName.bind(this);
    this.validRepeatPass = this.validRepeatPass.bind(this);
    this.onModifyUserGrop = this.onModifyUserGrop.bind(this);
  }

  onModifyUserGrop(id) {
    const { users } = this.state;
    for (let i = 0; i < users.length; i += 1) {
      if (users[i].id === id) {
        this.setState({ userName: users[i].userName });
        this.setState({ userId: users[i].id });
        this.validator.hideMessages();
        break;
      }
    }
  }

  validRepeatPass() {
    const {
      userPass, userRepeat,
    } = this.state;
    let rtdo = 1;
    if (userPass !== '' && userRepeat !== '') {
      if (userPass !== userRepeat) {
        rtdo = '';
      }
    }
    return rtdo;
  }

  existUserName() {
    const { users } = this.props;
    const {
      userName, userId,
    } = this.state;
    let rtdo = 1;
    for (let i = 0; i < users.length; i += 1) {
      if (users[i].userName === userName && userId === '') {
        rtdo = '';
      }
    }
    return rtdo;
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  async addUsers() { /* eslint-disable */
    const {
      userName, userPass, userId,
    } = this.state;
    const { onCloseModal, handleChangeEntity } = this.props;
    this.validator.showMessages();
    this.forceUpdate();
    if (this.validator.allValid()) {
      const caller = new WizardApiCall();
      let promise = {};
      try {
        if (userId === '') {
          promise = await caller.createUser(userName, userPass);
        } else {
          promise = await caller.editUser(userId, userName, userPass);
        }
      } catch (e) {
        console.log(e);
        return;
      }
      if (promise.Error.code === '0') {
        handleChangeEntity();
        onCloseModal();
      }
    }
  }

  render() {
    const { onCloseModal, userToModify } = this.props;
    const {
      userName, userId, userPass, userRepeat,
    } = this.state;
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title divTitle">
                <h4 className="bold-text">Usuarios de canje</h4>
              </div>
              <form className="form form--horizontal">
                <input type="hidden" name="userId" value={userId} />
                <Row>
                  <Col sm={12} md={12} lg={12}>
                    <Row>
                      <Col md={12} lg={12}>
                        <span>Usuario</span>
                        <input
                          name="userName"
                          component="input"
                          type="text"
                          placeholder="Usuario"
                          onChange={this.handleChange}
                          value={userName || ''}
                        />
                        <div>
                          {this.validator.message('Usuario', userName, 'required')}
                        </div>
                        <div>
                          {this.validator.message(
                            'Usuario no existente',
                            this.existUserName(), 'required',
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12}>
                        <span>Contraseña</span>
                        <input
                          name="userPass"
                          component="input"
                          type="password"
                          placeholder="Contraseña"
                          onChange={this.handleChange}
                          value={userPass || ''}
                        />
                        <div>
                          {this.validator.message('Contraseña', userPass, 'required')}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12}>
                        <span>Repetir contraseña</span>
                        <input
                          name="userRepeat"
                          component="input"
                          type="password"
                          placeholder="Repetir contraseña"
                          onChange={this.handleChange}
                          value={userRepeat || ''}
                        />
                      </Col>
                    </Row>
                    <div>
                      {this.validator.message('Repetir contraseña', userRepeat, 'required')}
                    </div>
                    <div>
                      {this.validator.message(
                        'Repetir contraseña sea igual a la contraseña',
                        this.validRepeatPass(), 'required',
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col md={12} lg={12}>
          <ButtonToolbar className="form__button-toolbar btn-center">
            <Button color="primary" type="button" onClick={onCloseModal}>Cancelar</Button>
            <Button color="primary" type="button" onClick={this.addUsers}>Guardar</Button>
          </ButtonToolbar>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up_exchange_user',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: false,
})(withTranslation('common')(PopupExchangeUser));
