import React, { PureComponent } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import renderCheckboxField from './CheckBox';
import SimpleReactValidator from '../../simple-react-validator';

class PopUpAudience extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    handleChangeAudienceAge: PropTypes.func.isRequired,
    handleChangeCheckBox: PropTypes.func.isRequired,
    handleChangeAudiencePeople: PropTypes.func.isRequired,
    handleChangeAudienceLanguage: PropTypes.func.isRequired,
    handleChangeAudienceInterest: PropTypes.func.isRequired,
    publicName: PropTypes.string.isRequired,
    publicFrom: PropTypes.objectOf(PropTypes.any).isRequired,
    publicLanguage: PropTypes.objectOf(PropTypes.any).isRequired,
    publicInterest: PropTypes.objectOf(PropTypes.any).isRequired,
    publicAgeFrom: PropTypes.objectOf(PropTypes.any).isRequired,
    publicAgeUpTo: PropTypes.objectOf(PropTypes.any).isRequired,
    publicSexM: PropTypes.bool.isRequired,
    publicSexF: PropTypes.bool.isRequired,
    publicSexNB: PropTypes.bool.isRequired,
    agesFrom: PropTypes.arrayOf(PropTypes.object).isRequired,
    agesUpTo: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor() {
    super();
    this.state = {
      languages: [
        { label: 'Español', value: 1 },
        { label: 'Inglés', value: 2 },
      ],
      interests: [
        { label: 'Electrodomésticos', value: 1 },
        { label: 'Ropa', value: 2 },
      ],
      peopleFrom: [
        { label: 'Todas las personas', value: 1 },
        { label: 'Viven en este lugar', value: 2 },
        { label: 'De visita en este lugar', value: 3 },
      ],
    };
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    this.submitForm = this.submitForm.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
  }

  handleChangeCheckBox(section, event) {
    const { handleChangeCheckBox } = this.props;
    if (event.target === undefined) {
      handleChangeCheckBox(section, event);
    } else {
      handleChangeCheckBox(section, event.target.checked);
    }
  }

  submitForm() {
    const { onCloseModal } = this.props;
    const {
      publicSexNB, publicSexF, publicSexM,
    } = this.props;
    let permitir = true;
    let selected = 0;
    if (publicSexM) {
      selected += 1;
    }
    if (publicSexF) {
      selected += 1;
    }
    if (publicSexNB) {
      selected += 1;
    }

    if (selected === 2) {
      NotificationManager.error('Seleccionar una opcion , todos o ninguna del sexo de la audiencia');
      permitir = false;
    }

    if (this.validator.allValid() && permitir) {
      onCloseModal();
    }
    this.validator.showMessages();
    this.forceUpdate();
  }

  render() {
    const {
      handleSubmit, t, handleChange, handleChangeAudienceInterest, handleChangeAudienceLanguage, publicSexNB,
      publicName, publicAgeUpTo, publicAgeFrom, handleChangeAudiencePeople, publicFrom, publicSexF, publicSexM,
      publicLanguage, publicInterest, agesFrom, agesUpTo, handleChangeAudienceAge, onCloseModal,
    } = this.props;
    const { languages, interests, peopleFrom } = this.state;
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title" style={{ textAlign: 'center' }}>
                <h5 className="bold-text">{t('Definir Público ')}</h5>
                <h5 className="subhead">Complete todos los campos</h5>
              </div>
              <form className="form form--horizontal" onSubmit={handleSubmit}>
                <Row>
                  <Col sm={0} md={3} />
                  <Col sm={12} md={9}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Nombre del Público</span>
                      <div className="form__form-group-field">
                        <input
                          name="publicName"
                          component="input"
                          type="text"
                          placeholder="nombre"
                          style={{ width: '50%' }}
                          onChange={handleChange}
                          value={publicName}
                        />
                      </div>
                      <div className="form__form-group-field">
                        {this.validator.message('nombre', publicName, 'required')}
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Personas que</span>
                      <div className="form__form-group-field">
                        <Select
                          options={peopleFrom}
                          className="form__form-group-input-wrap-own"
                          classNamePrefix="select"
                          onChange={handleChangeAudiencePeople}
                          style={{ width: '50%' }}
                          name="peopleFromResident"
                          value={publicFrom}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Sexo</span>
                      <div className="form__form-group-field">
                        <Field
                          name="publicSexM"
                          type="checkbox"
                          defaultChecked={publicSexM}
                          component={renderCheckboxField}
                          label="Hombres"
                          onChange={e => this.handleChangeCheckBox('publicSexM', e)}
                        />
                        <div style={{ marginLeft: '21px' }}>
                          <Field
                            name="publicSexF"
                            component={renderCheckboxField}
                            type="checkbox"
                            defaultChecked={publicSexF}
                            label="Mujeres"
                            onChange={e => this.handleChangeCheckBox('publicSexF', e)}
                          />
                        </div>
                        <div style={{ marginLeft: '15px' }}>
                          <Field
                            name="publicSexNB"
                            component={renderCheckboxField}
                            type="checkbox"
                            defaultChecked={publicSexNB}
                            label="No binario"
                            onChange={e => this.handleChangeCheckBox('publicSexNB', e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Edad</span>
                      <div className="form__form-group-field" style={{ width: '300px' }}>
                        <Select
                          name="publicAgeFrom"
                          options={agesFrom}
                          className="form__form-group-input-wrap-own"
                          placeholder="desde"
                          classNamePrefix="select"
                          style={{ width: '20%' }}
                          onChange={(e) => { handleChangeAudienceAge('publicAgeFrom', e); }}
                          value={publicAgeFrom}
                        />
                        <div style={{ padding: '10px' }}>-</div>
                        <Select
                          name="publicAgeUpTo"
                          options={agesUpTo}
                          className="form__form-group-input-wrap-own"
                          placeholder="hasta"
                          classNamePrefix="select"
                          style={{ width: '20%' }}
                          onChange={(e) => { handleChangeAudienceAge('publicAgeUpTo', e); }}
                          value={publicAgeUpTo}
                        />
                      </div>
                      <div className="form__form-group-field">
                        {this.validator.message('edad desde', publicAgeUpTo, 'required')}
                      </div>
                      <div className="form__form-group-field">
                        {this.validator.message('edad hasta', publicAgeFrom, 'required')}
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Idiomas</span>
                      <div className="form__form-group-field">
                        <Select
                          options={languages}
                          className="form__form-group-input-wrap-own"
                          classNamePrefix="select"
                          onChange={handleChangeAudienceLanguage}
                          style={{ width: '50%' }}
                          name="publicLanguage"
                          value={publicLanguage}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Intereses</span>
                      <div className="form__form-group-field">
                        <Select
                          options={interests}
                          className="form__form-group-input-wrap-own"
                          classNamePrefix="select"
                          onChange={handleChangeAudienceInterest}
                          style={{ width: '50%' }}
                          name="publicInterest"
                          value={publicInterest}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <ButtonToolbar className="form__button-toolbar" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <Button type="button" onClick={onCloseModal}>
                    Cancelar
                  </Button>
                  <Button color="primary" type="button" onClick={this.submitForm}>Continuar</Button>
                </ButtonToolbar>
                <NotificationContainer />
              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up_audience', // a unique identifier for this form
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(withTranslation('common')(PopUpAudience));
