import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import Linkify from 'react-linkify';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import renderDropZoneField from './DropZone';

class PopUpUploadFiles extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleChangeFiles: PropTypes.func.isRequired,
    object: PropTypes.string,
    material: PropTypes.string,
    thumbnail: PropTypes.string,
    texture: PropTypes.string,
    vimage: PropTypes.string,
    captureSound: PropTypes.string,
  };

  static defaultProps = {
    object: '',
    material: '',
    thumbnail: '',
    texture: '',
    vimage: '',
    captureSound: '',
  };

  constructor(props) {
    super(props);
    this.state = {
    };
    this.submitForm = this.submitForm.bind(this);
    this.close = this.close.bind(this);
  }

  submitForm() {
    const { onSubmit } = this.props;
    onSubmit();
  }

  close() {
    const { onSubmit } = this.props;
    onSubmit();
  }

  render() {
    const {
      onSubmit, handleChangeFiles, material, captureSound, object, texture, thumbnail, vimage,
    } = this.props;
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Archivos Multimedia</h5>
              </div>
              <form className="form form--horizontal" onSubmit={onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Modelo 3D</span>
                  <div className="form__form-group-field">
                    <Field
                      name="object"
                      component={renderDropZoneField}
                      onChange={e => handleChangeFiles('object', e)}
                    />
                    <div style={{ marginLeft: '20px' }} />
                    { object !== null && object !== '' && (
                      <Linkify>URL: {object}</Linkify>
                    )}
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Material 3D</span>
                  <div className="form__form-group-field">
                    <Field
                      name="material"
                      component={renderDropZoneField}
                      onChange={e => handleChangeFiles('material', e)}
                    />
                    <div style={{ marginLeft: '20px' }} />
                    { material !== null && material !== '' && (
                      <Linkify>URL: {material}</Linkify>
                    )}
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Textura</span>
                  <div className="form__form-group-field">
                    <Field
                      name="texture"
                      component={renderDropZoneField}
                      onChange={e => handleChangeFiles('texture', e)}
                    />
                    <div style={{ marginLeft: '20px' }} />
                    { texture !== null && texture !== '' && (
                      <Linkify>URL: {texture}</Linkify>
                    )}
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Imagen del Voucher</span>
                  <div className="form__form-group-field">
                    <Field
                      name="vimage"
                      component={renderDropZoneField}
                      onChange={e => handleChangeFiles('vimage', e)}
                    />
                    <div style={{ marginLeft: '20px' }} />
                    { vimage !== null && vimage !== '' && (
                      <Linkify>URL: {vimage}</Linkify>
                    )}
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Sonido de Captura</span>
                  <div className="form__form-group-field">
                    <Field
                      name="captureSound"
                      component={renderDropZoneField}
                      onChange={e => handleChangeFiles('captureSound', e)}
                    />
                    <div style={{ marginLeft: '20px' }} />
                    { captureSound !== null && captureSound !== '' && (
                      <Linkify>URL: {captureSound}</Linkify>
                    )}
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Thumbnail</span>
                  <div className="form__form-group-field">
                    <Field
                      name="thumbnail"
                      component={renderDropZoneField}
                      onChange={e => handleChangeFiles('thumbnail', e)}
                    />
                    <div style={{ marginLeft: '20px' }} />
                    { thumbnail !== null && thumbnail !== '' && (
                      <Linkify>URL: {thumbnail}</Linkify>
                    )}
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button type="button" onClick={this.close}>
                    Cancelar
                  </Button>
                  <Button color="primary" type="button" onClick={this.submitForm}>Continuar</Button>
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up_upload', // a unique identifier for this form
  destroyOnUnmount: false, //        <------ preserve form data
})(withTranslation('common')(PopUpUploadFiles));
