import React, { PureComponent } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonToolbar,
} from 'reactstrap';
import swal from '@sweetalert/with-react';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import isEqual from 'react-fast-compare';
import ListItem from './ListItem';
import WizardApiCall from '../../../containers/NewCampaign/components/WizardApiCall';
import PopupExchangeAddUser from './PopupExchangeAddUser';


class PopupExchangeUser extends PureComponent {
static propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  zoneToModify: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedExchangeZone: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeEntity: PropTypes.func.isRequired,
};

constructor(props) {
  super(props);
  this.state = {
    name: '',
    users: [],
    selectedUsers: [],
    modifying: false,
    userToModify: {},
    modalUserFlag: false,
  };
  this.state.name = props.selectedExchangeZone.name;
  if (props.selectedExchangeZone.exchangeUsers) {
    this.state.selectedUsers = props.selectedExchangeZone.exchangeUsers;
  }
  this.submitForm = this.submitForm.bind(this);
  this.handleChange = this.handleChange.bind(this);
  this.handleChangeUsers = this.handleChangeUsers.bind(this);
  this.onOpenModal = this.onOpenModal.bind(this);
  this.onOpenEditModal = this.onOpenEditModal.bind(this);
  this.modify = this.modify.bind(this);
  this.modifyUser = this.modifyUser.bind(this);
  this.IsSelectedUser = this.IsSelectedUser.bind(this);
  this.onDeleteUserGrop = this.onDeleteUserGrop.bind(this);
  this.usersList = this.usersList.bind(this);
  this.onCloseUserModal = this.onCloseUserModal.bind(this);
}

async componentDidMount() {
  this.usersList();
}

onOpenModal() {
  this.setState({ modifying: false }, () => {
    this.setState({ userToModify: {} });
    this.setState({ modalUserFlag: true });
  });
}

onOpenEditModal() {
  this.setState({ modalUserFlag: true });
}

async onCloseUserModal() {
  this.setState({ modalUserFlag: false });
}

onDeleteUserGrop() { /* eslint-disable */
  const { userToModify } = this.state;
  swal({
    title: 'Eliminar usuario de centros de canje',
    text: '¿Está seguro que desea eliminar al usuario '+ userToModify.userName +'?',
    buttons: ['No', 'Si'],
  })
    .then(async (value)=> {
      if (value) {
        const caller = new WizardApiCall();
        let promise = {};
        try {
          promise = await caller.deleteUser(userToModify.id);
        } catch (e) {
          console.log(e);
          return;
        }
        if (promise.Error.code === '0') {
          this.usersList();
        }
      }});
}

async usersList() {
  const caller = new WizardApiCall();
  let promise = {};
  try {
    promise = await caller.getUsers();
  } catch (e) {
    console.log(e);
    return;
  }
  if (promise.Content) {
    const list = promise.Content;
    await list.sort((a, b) => (b.userName < a.userName ? 1 : -1));
    this.handleChangeUsers('users', list);
  }
}

modify(id, valueChk) {
  const { selectedUsers, users } = this.state;
  if (valueChk) {
    for (let i = 0; i < users.length; i += 1) {
      if (users[i].id === id) {
        const newData = [
          ...selectedUsers,
          users[i],
        ];
        this.setState({ selectedUsers: newData });
      }
    }
  } else {
    const newData = [];
    for (let i = 0; i < selectedUsers.length; i += 1) {
      if (selectedUsers[i].id !== id) {
        newData.push(selectedUsers[i]);
      }
    }
    this.setState({ selectedUsers: newData });
  }
}

modifyUser(id, name) {
  const { users } = this.state;
  for (let i = 0; i < users.length; i += 1) {
    if (users[i].id === id) {
      this.setState({ userToModify: users[i] });
      this.setState({ modifying: true });
    }
  }
  console.log(name);
}

IsSelectedUser(id) {
  const {
    selectedUsers,
  } = this.state;
  let rtdo = false;
  if (selectedUsers.length>0) {
    for (let i = 0; i < selectedUsers.length; i += 1) {
      if (selectedUsers[i].id === id) {
        rtdo = true;
      }
    }
  }
  return rtdo;
}

async submitForm() {
  const {
    name, selectedUsers,
  } = this.state;
  const {
    onCloseModal, selectedExchangeZone, handleChangeEntity, zoneToModify,
  } = this.props;
  const group = {
    id: parseInt(selectedExchangeZone.id, 10),
    name,
    exchangeCenters: selectedExchangeZone.exchangeCenters,
    exchangeUsers: selectedUsers,
  };
  if (!isEqual(selectedExchangeZone, group)) {
    const caller = new WizardApiCall();
    await caller.modifyGroup(zoneToModify.zone.id, group);
    handleChangeEntity();
  }
  onCloseModal();
}

handleChange(event) {
  const { name, value } = event.target;
  this.setState({ [name]: value });
}

handleChangeUsers(name, value) {
  this.setState({ [name]: value });
}

render() {
  const { onCloseModal } = this.props;
  const {
    name, users, selectedUsers, modifying, userToModify, modalUserFlag,
  } = this.state;
  return (
    <div className="theme-light">
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title divTitle">
              <h4 className="bold-text">Usuarios asignados al grupo de centros de canje: {name}</h4>
            </div>
            <form className="form form--horizontal">
              <Row>
                <Col md={1} lg={1}>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <div
                    className="boxGroupsCenters"
                    name="zones"
                    component="input"
                    type="text"
                  >
                    <div className="boxed-container">
                      {
                        users.map((c, index) => { /* eslint-disable */
                          let value=this.IsSelectedUser(c.id);
                          return (<ListItem
                            modify={this.modify}
                            modifyUser={this.modifyUser}
                            selectedUsers={selectedUsers}
                            key={index}
                            id={c.id}
                            name={c.userName}
                            classSelected={userToModify.id === c.id ? 'zoneSelected' : 'normalZone'}
                            value={value}
                          />)
                        })
                      }
                    </div>
                  </div>
                </Col>
                <Col md={5} lg={5}>
                  <ButtonToolbar
                    className="form__button-toolbar wizard__toolbar buttonToolbarBotonera"
                  >
                    <Button
                      color="primary"
                      type="button"
                      className="buttonBotonera"
                      onClick={this.onOpenModal}
                    > Agregar Usuario
                    </Button>
                    <Button
                      disabled={!modifying}
                      color="primary"
                      type="button"
                      className="previous"
                      onClick={this.onOpenEditModal}
                      className="buttonBotonera"
                    > Editar Usuario
                    </Button>
                    <Button
                      disabled={!modifying}
                      color="primary"
                      type="button"
                      className="previous"
                      onClick={() => this.onDeleteUserGrop()}
                      className="buttonBotonera"
                    > Eliminar Usuario
                    </Button>
                  </ButtonToolbar>
                  <Modal open={modalUserFlag} onClose={this.onCloseUserModal} center>
                    <PopupExchangeAddUser
                      onCloseModal={this.onCloseUserModal}
                      userToModify={userToModify}
                      handleChangeEntity={this.usersList}
                      users={users}
                    />
                  </Modal>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} lg={12}>
        <ButtonToolbar className="form__button-toolbar btn-center">
          <Button color="primary" type="button" onClick={onCloseModal}>Cancelar</Button>
          <Button color="primary" type="button" onClick={this.submitForm}>Guardar</Button>
        </ButtonToolbar>
      </Col>
    </div>
  );
}
}

export default reduxForm({
  form: 'pop_up_exchange_user',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: false,
})(withTranslation('common')(PopupExchangeUser));
