import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import SimpleReactValidator from '../../../shared/simple-react-validator';
import WizardApiCall from './WizardApiCall';
import { AUTHENTICATION } from '../../../constants';

class WizardFormOne extends PureComponent {
  static propTypes = {
    handleChangeEntity: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    campaign: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
    campaignCreated: PropTypes.bool.isRequired,
    returnFlag: PropTypes.bool,
  };

  static defaultProps = {
    returnFlag: true,
  };

  static returnToCampaigns() {
    window.location = '/pages/campaigns';
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      campaignGoals: [],
      campaignSelectedGoal: {
        label: 'Select',
        value: 0,
      },
      campaignId: '',
      campaignBudget: '',
      campaignName: '',
      campaignStartDate: new Date(),
    };
    if (props.campaign.id !== 0 && props.campaign.id !== undefined) {
      this.state.campaignId = props.campaign.id;
      this.state.campaignName = props.campaign.name;
      this.state.campaignBudget = props.campaign.budget;
      this.state.campaignStartDate = props.campaign.startDate;
      if (props.campaign.goal !== undefined) {
        this.state.campaignSelectedGoal = props.campaign.goal;
      }
    }
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      validators: {
        select_default: { // name the rule
          message: 'El campo :attribute es requerido.',
          rule: (val, params, validator) => (validator.helpers.testRegex(val, /[^0]+/i) && params.indexOf(val) === -1),
          messageReplace: (message, params) => message.replace(':attribute', this.helpers.toSentence(params)),
          required: true,
        },
      },
    });
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createCampaign = this.createCampaign.bind(this);
    this.handleChangeGoal = this.handleChangeGoal.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  async componentDidMount() {
    const { campaign } = this.props;
    if (localStorage.getItem(AUTHENTICATION)) {
      const caller = new WizardApiCall();
      const goals = await caller.getCampaignGoals();
      const traducedGoals = [];
      const listGoals = goals.Content;
      for (let i = 0; i < listGoals.length; i += 1) {
        traducedGoals.push({ label: listGoals[i].name, value: listGoals[i].id });
      }
      this.setState({ campaignGoals: traducedGoals });
      if (campaign.id !== undefined) {
        this.setState({ campaignSelectedGoal: traducedGoals[campaign.goalId - 1] });
      }
    } else {
      window.location = '/';
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChangeGoal(selected) {
    const { campaignSelectedGoal } = this.state;
    this.setState({ campaignSelectedGoal: { label: selected.label, value: selected.value } }, () => {
      console.log(campaignSelectedGoal);
    });
  }

  handleChangeDate(selected) {
    this.setState({ campaignStartDate: selected });
  }

  async createCampaign() {
    const {
      campaignSelectedGoal, campaignName, campaignBudget, campaignStartDate,
    } = this.state;
    const { handleChangeEntity } = this.props;
    const camp = {
      id: 0,
      goalId: campaignSelectedGoal.value,
      name: campaignName,
      budget: campaignBudget.replace(/[^a-zA-Z0-9-.]/g, ''),
      startDate: campaignStartDate,
    };
    let campaign = '';
    const caller = new WizardApiCall();
    try {
      campaign = await caller.createCampaign(camp);
      console.log(campaign.Content.CampaignId);
    } catch (e) {
      return;
    }
    if (campaign === undefined) {
      return;
    }
    camp.id = String(campaign.Content.CampaignId);
    camp.goal = { label: campaignSelectedGoal.label, value: campaignSelectedGoal.value };
    handleChangeEntity('campaign', camp);
    handleChangeEntity('campaignCreated', true);
    NotificationManager.success('Campaña creada correctamente.', '');
  }

  async modifyCampaign() {
    const {
      campaignSelectedGoal, campaignName, campaignBudget, campaignStartDate,
    } = this.state;
    const { handleChangeEntity, campaign } = this.props;
    if (
      campaign.goalId === campaignSelectedGoal.value
      && campaign.name === campaignName
      && campaign.startDate === campaignStartDate
      && campaign.budget === campaignBudget
    ) {
      console.log('Son iguales.');
      return;
    }
    const camp = {
      id: parseInt(campaign.id, 10),
      goalId: campaignSelectedGoal.value,
      name: campaignName,
      budget: campaignBudget.replace(/[^a-zA-Z0-9]/g, ''),
      startDate: campaignStartDate,
      state: campaign.state,
    };
    let campaignModify = '';
    const caller = new WizardApiCall();
    try {
      campaignModify = await caller.modifyCampaign(camp);
      NotificationManager.success('Campaña modificada correctamente.', ':');
    } catch (e) {
      console.log(e);
      return;
    }
    if (campaignModify === undefined) {
      return;
    }
    camp.goal = { label: camp.goal, value: campaignSelectedGoal.value };
    handleChangeEntity('campaign', camp);
  }

  async submitForm() {
    const { nextPage, campaign, campaignCreated } = this.props;
    this.validator.showMessages();
    this.forceUpdate();
    if (this.validator.allValid()) {
      console.log(campaign.id);
      if (!campaignCreated) {
        await this.createCampaign();
      } else {
        await this.modifyCampaign();
      }
      nextPage();
    }
  }

  render() {
    const {
      handleSubmit, returnFlag,
    } = this.props;
    const {
      campaignGoals, campaignSelectedGoal, campaignName, campaignBudget, campaignStartDate,
    } = this.state;
    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Ingresá los datos de la campaña</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Objetivo de tu campaña</span>
          <div className="form__form-group-field">
            <Select
              options={campaignGoals}
              className="form__form-group-input-wrap"
              classNamePrefix="select"
              onChange={e => this.handleChangeGoal(e)}
              name="goal"
              value={campaignSelectedGoal}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('objetivo', campaignSelectedGoal.value, 'required|select_default')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Nombre de campaña</span>
          <div className="form__form-group-field">
            <input
              name="campaignName"
              component="input"
              type="text"
              value={campaignName}
              placeholder="nombre"
              onChange={this.handleChange}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('nombre', campaignName, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Presupuesto de campaña U$$</span>
          <div className="form__form-group-field">
            <CurrencyInput
              name="campaignBudget"
              value={campaignBudget}
              onChangeEvent={this.handleChange}
              decimalSeparator="."
              thousandSeparator=","
              prefix="$"
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('presupuesto', campaignBudget, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Fecha de Inicio de campaña</span>
          <div className="form__form-group-field">
            <DatePicker
              name="campaignStartDate"
              component="input"
              type="text"
              selected={campaignStartDate}
              onChange={this.handleChangeDate}
              dateFormat="dd/MM/yyyy"
            />
            <div className="form__form-group-icon">
              <CalendarBlankIcon />
            </div>
          </div>
          <div className="form__form-group-field">
            {this.validator.message('fecha de inicio', campaignStartDate, 'required')}
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button
            disabled={returnFlag}
            color="primary"
            type="button"
            onClick={WizardFormOne.returnToCampaigns}
            className="previous"
          >Volver
          </Button>
          <Button color="primary" type="button" onClick={this.submitForm} className="previous">Continuar</Button>
        </ButtonToolbar>
        <NotificationContainer />
      </form>
    );
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(WizardFormOne);
