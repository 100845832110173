import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WizardApiCall from './components/WizardApiCall';
import Wizard from './components/WizardForm';

const WizardForm = ({ t }) => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">{t('Asistente de creación de campañas')}</h3>
        <h3 className="page-subhead subhead">Usa este asistente para crear una nueva campaña en Kchapp
        </h3>
      </Col>
    </Row>
    <Wizard onSubmit={WizardApiCall} />
  </Container>
);

WizardForm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(WizardForm);
