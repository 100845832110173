import React, { PureComponent } from 'react';
import {
  Button, Col, Container, Row,
} from 'reactstrap';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import CampaignList from './components/CampaignList';
import WizardForm from '../NewCampaign/components/WizardForm';
import WizardApiCall from '../NewCampaign/components/WizardApiCall';
import WizardApiCallEdit from './components/WizardApiCallEdit';


export default class EditionWizard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      buttonFlag: true,
      selectedCampaign: {},
      actionCampaign: 'Detener',
      selectedRow: -1,
      campaigns: [],
    };
    this.disableButton = this.disableButton.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleChangeCampaign = this.handleChangeCampaign.bind(this);
    this.handleStateClick = this.handleStateClick.bind(this);
    this.campaignList = this.campaignList.bind(this);
  }

  async componentDidMount() {
    this.campaignList();
  }

  componentWillReceiveProps() {
    this.state.editorFlag = false;
    this.state.selectedCampaign = {};
    this.state.buttonFlag = true;
    this.state.actionCampaign = 'Detener';
    this.state.selectedRow = -1;
  }

  getParsedDate(date) {
    const dateNew = String(date).split(' ');
    const days = String(dateNew[0]).split('/');
    console.log(this);
    return `${days[2]}-${days[1]}-${days[0]} ${dateNew[1]}`;
  }

  async campaignList() {
    let campaigns = [];
    const caller = new WizardApiCallEdit();
    try {
      campaigns = await caller.getCampaigns();
    } catch (e) {
      console.log(e);
      return;
    }
    this.setState({ campaigns: campaigns.Content });
  }

  handleChangeCampaign(campaign, index) {
    this.setState({ selectedCampaign: campaign });
    let action = '';
    if (campaign.state === '0') {
      action = 'Reanudar';
    } else if (campaign.state === '1') {
      action = 'Detener';
    }
    this.setState({ actionCampaign: action });
    this.setState({ selectedRow: index });
  }

  disableButton(flagValue) {
    this.setState({ buttonFlag: flagValue });
  }

  async handleStateClick() {
    const {
      selectedCampaign,
    } = this.state;
    let mensaje = '';
    const camp = {
      id: parseInt(selectedCampaign.id, 10),
      goalId: selectedCampaign.goalId,
      name: selectedCampaign.name,
      budget: selectedCampaign.budget,
      startDate: new Date(moment(this.getParsedDate(selectedCampaign.startDate), 'YYYY-MM-DD HH:mm:ss').toDate()),
      state: selectedCampaign.state,
    };
    if (selectedCampaign.state === '1') {
      mensaje = '¡Campaña detenida!';
      camp.state = '0';
    } else if (selectedCampaign.state === '0') {
      mensaje = '¡Campaña reanudada!';
      camp.state = '1';
    }
    const caller = new WizardApiCall();
    try {
      let z = {};
      swal({
        title: '',
        text: '',
        content: (
          <div className="spinner-border" role="status">
            <span className=" sr-only">Loading...</span>
          </div>
        ),
        buttons: false,
        closeOnClickOutside: false,
        closeOnEscpor: false,
        showConfirmButton: false,
      });
      if (selectedCampaign.state === '1') {
        z = await caller.stopCampaign(camp);
      } else if (selectedCampaign.state === '0') {
        z = await caller.activateCampaign(camp);
      }
      swal.stopLoading();
      swal.close();
      if (z.Error.code !== '0') {
        swal({
          title: '¡No se pudo actualizar campaña!',
          text: z.Error.message,
          icon: 'error',
          button: 'OK',
        });
      } else {
        swal({
          title: mensaje,
          icon: 'success',
          button: 'OK',
        }).then(() => {
          this.campaignList();
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async handleEditClick() {
    this.setState({ editorFlag: true });
  }

  render() {
    const {
      buttonFlag, editorFlag, selectedCampaign, actionCampaign, selectedRow, campaigns,
    } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <div className="topbar_guia" style={{ background: '#eaecef' }}>
              <div className="topbar__wrapper">
                <Row>
                  <Col sm={12} md={4}>
                    <h1 className="page-title">Campañas</h1>
                  </Col>
                  <Col sm={12} md={8}>
                    <div className="topbar_buttons">
                      <Row>
                        <Col sm={12} md={4}>
                          <Button
                            disabled={buttonFlag}
                            color="primary"
                            type="button"
                            onClick={this.handleStateClick}
                          >{actionCampaign} Campaña
                          </Button>
                        </Col>
                        <Col sm={12} md={4}>
                          <Button
                            disabled={buttonFlag}
                            color="primary"
                            type="button"
                          >Eliminar Campaña
                          </Button>
                        </Col>
                        <Col sm={12} md={4}>
                          <Button
                            disabled={buttonFlag}
                            color="primary"
                            type="button"
                            onClick={this.handleEditClick}
                          >Editar Campaña
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {!editorFlag
        && (
        <CampaignList
          disableButton={this.disableButton}
          handleChangeCampaign={this.handleChangeCampaign}
          selectedRow={selectedRow}
          campaigns={campaigns}
        />
        )
        }
        {editorFlag
        && (
          <div>
            <WizardForm
              selectedCampaign={selectedCampaign}
              fromEditor
              title1="Editar Información de Campaña"
              title2="Editar Zonas"
              title3="Anuncios"
              title4="Editar Anuncio"
              returnFlag={false}
            />
          </div>
        )
        }
      </Container>
    );
  }
}
