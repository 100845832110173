import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import ApiCall from './components/ApiCall';
import 'react-notifications/lib/notifications.css';

export default class ExchangeSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { code } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3
              className="page-title"
              style={{ textAlign: 'center', marginTop: '150px', fontSize: '200%' }}
            >Ingrese el código único de canje
            </h3>
            <h3
              className="page-subhead subhead"
              style={{ textAlign: 'center' }}
            >El código único de canje debe solicitarlo al usuario final, recuerde que
              también puede realizar el canje escaneando el código QR en el dispositivo del usuario.
            </h3>
            <div style={{ textAlign: 'center' }}>
              <input
                name="code"
                onChange={this.handleChange}
                style={{
                  height: '40px', width: '200px', fontSize: '150%', color: '#646777',
                }}
              />
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={async () => {
                  const caller = new ApiCall();
                  const response = await caller.exchangeRegister(code);
                  if (response.Error.code !== '0') {
                    NotificationManager.error(response.Error.message);
                  } else {
                    NotificationManager.success('Canje realizado correctamente');
                  }
                }}
              >Canjear
              </button>
              <NotificationContainer />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
