import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';

export default class ListItem extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    classSelected: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    modify: PropTypes.func.isRequired,
    selectedUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.bool.isRequired,
    modifyUser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: false,
    };
    this.state.value = props.value;
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange() {
    const { ...props } = this.props;
    const { value } = this.state;
    props.modify(props.id, !value);
    this.setState({ value: !value });
  }

  onClick() {
    const { ...props } = this.props;
    props.modifyUser(props.id, props.name);
  }

  render() {
    const {
      name,
      classSelected,
      value,
    } = this.props;
    return (
      <div className={classSelected}>
        <Col md={12}>
          <FormGroup check>
            <Label className="chkLabelItem" check>
              <Input type="checkbox" checked={value} className="chkItem chkUserItem" onChange={this.onChange} />{' '}
              <button
                className={classSelected}
                onClick={this.onClick}
                style={{
                  cursor: 'pointer', border: 'none', fontWeight: 'bold',
                }}
                type="button"
              >
                {name}
              </button>
            </Label>
          </FormGroup>
        </Col>
      </div>
    );
  }
}
