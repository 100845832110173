import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, ButtonToolbar,
} from 'reactstrap';
import Geosuggest from 'react-geosuggest';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '798px',
  height: '366px',
};

const bottomStyles = {
  position: 'absolute',
  top: '410px',
};

class MapContainer extends PureComponent {
  static propTypes = {
    centers: PropTypes.arrayOf(PropTypes.any),
    handleChangeCenters: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onePoint: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      centers: [],
      markerFlag: false,
      pointFromSearchInput: { latitude: -31.65464054, longitude: -64.67023386 },
    };
    if (props.centers !== undefined) {
      this.state.centers = props.centers;
    }
    if (props.centers !== undefined) {
      if (props.centers.length === 1 && props.centers[0].lat) {
        this.state.pointFromSearchInput = { latitude: props.centers[0].lat, longitude: props.centers[0].lng };
      }
    }
    this.setStateForCentersClick = this.setStateForCentersClick.bind(this);
    this.removePointFromArray = this.removePointFromArray.bind(this);
    this.onInputOptionClick = this.onInputOptionClick.bind(this);
    this.setStateForCentes = this.setStateForCentes.bind(this);
    this.displayMarkers = this.displayMarkers.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.submitMap = this.submitMap.bind(this);
  }

  onMapClick(t, map, coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setStateForCentersClick(lat, lng);
  }

  onInputOptionClick(suggest) {
    if (suggest !== undefined) {
      this.setStateForCentes(suggest);
    }
  }

  displayMarkers = () => { /* eslint-disable*/
    const { centers, markerFlag } = this.state;
    return centers.map((store, index) => {
      return <Marker hide={markerFlag} key={index} id={index} position={{
        lat: store.latitude,
        lng: store.longitude
      }}
                     onClick={this.removePointFromArray} />
    })
  }

  async submitMap() {
    const { submitForm } = this.props;
    const { centers } = this.state;
    const { handleChangeCenters } = this.props;
    await handleChangeCenters('centers', centers);
    submitForm();
  }

  removePointFromArray(pointToRemove) {
    const { centers } = this.state;
    let centersAux=[];
    for (let i = 0; i < centers.length; i += 1) {
      if (centers[i].latitude !== pointToRemove.position.lat || centers[i].longitude !== pointToRemove.position.lng) {

        centersAux.push(centers[i]);

      }
    }
    this.setState({ centers: centersAux });
    this.setState({ markerFlag: true });
    this.displayMarkers();
    const { markerFlag } = this.state;
    this.setState({ markerFlag: false }, () => {
      this.displayMarkers();
    });
  }

  setStateForCentersClick(lat, lng) {
    const { onePoint } = this.props;
    const { centers } = this.state;
    if (!onePoint || (onePoint && centers.length === 0)) {
      this.setState(prevState => ({currentStep: prevState.currentStep + 1}));
      this.setState(previousState => ({
        centers: [...previousState.centers, { latitude: lat, longitude: lng },
        ],
      }));
    }
    if (onePoint && centers.length === 1) {
      this.setState(() => ({
        centers: [{ latitude: lat, longitude: lng }],
      }));
    }
    this.setState(() => ({
      pointFromSearchInput: { latitude: lat, longitude: lng },
    }));
  }

  setStateForCentes(suggest) {
    const { onePoint } = this.props;
    const { centers } = this.state;
    if(!onePoint || (onePoint && centers.length === 0)) {
      this.setState(prevState => ({currentStep: prevState.currentStep + 1}));
      this.setState(previousState => ({
        centers: [...previousState.centers, {lat: suggest.location.lat, lng: suggest.location.lng},
        ],
      }));
    }
    if(onePoint && centers.length === 1) {
      this.setState(() => ({
        centers: [ { lat: suggest.location.lat, lng: suggest.location.lng } ],
      }));
    }
    this.setState(() => ({
      pointFromSearchInput: { lat: suggest.location.lat, lng: suggest.location.lng },
    }));
  }

  render() {
    const {
      onCloseModal,
    } = this.props;
    const {
      pointFromSearchInput, centers,
    } = this.state;
    return (
      <div>
        <div>
          <Geosuggest
            style={{
              'input': { maxHeight: '100px' },
              'suggests': { backgroundColor: 'white', zIndex: '99999', position: 'absolute', width: '398px' },
              'suggestItem': {} }}
            placeholder="Buscar lugares..."
            onSuggestSelect={this.onInputOptionClick}
          />
        </div>
        <div>
          <Map /* eslint-disable-next-line */
            google={this.props.google}
            zoom={8}
            style={mapStyles}
            center={{ lat: pointFromSearchInput.latitude, lng: pointFromSearchInput.longitude }}
            initialCenter={{ lat: pointFromSearchInput.latitude, lng: pointFromSearchInput.longitude }}
            onClick={this.onMapClick}
          >
            {this.displayMarkers()}
          </Map>
        </div>
        <Col md={12} lg={12} style={bottomStyles}>
          <ButtonToolbar className="form__button-toolbar btn-center">
            <Button color="primary" type="button" onClick={onCloseModal}>Cancelar</Button>
            <Button color="primary" type="button" onClick={this.submitMap}>Guardar</Button>
          </ButtonToolbar>
        </Col>
      </div>
    );
  }
}

// Especifica los valores por defecto de props:
MapContainer.defaultProps = {
  centers: []
};

export default GoogleApiWrapper({
  apiKey: process.env.MAP_API_KEY,
})(MapContainer);
