import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import GoogleMap from '../../../containers/GoogleApiMaps/CouponSpecificLocation/GoogleCouponLocaton';
import SimpleReactValidator from '../../simple-react-validator';
import WizardApiCall from '../../../containers/NewCampaign/components/WizardApiCall';


class PopupSpecificLocation extends PureComponent {
  static propTypes = {
    zoneId: PropTypes.number.isRequired,
    centers: PropTypes.arrayOf(PropTypes.any).isRequired,
    regions: PropTypes.arrayOf(PropTypes.any).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    handleChangeCenters: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      centers: [],
      regions: [],
    };
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    if (props.regions !== undefined) {
      this.state.regions = props.regions;
    }
    if (props.centers !== undefined || props.centers.length !== 0) {
      this.state.centers = props.centers;
    }
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCenters = this.handleChangeCenters.bind(this);
  }

  async submitForm() {
    const { zoneId, onCloseModal, handleChangeCenters } = this.props;
    const { name, centers } = this.state;
    console.log(name);
    this.validator.showMessages();
    this.forceUpdate();
    const caller = new WizardApiCall();
    try {
      const responseValidate = await caller.validateCorrectLocation(zoneId, centers[0].lat, centers[0].lng);
      if (responseValidate.Error.code !== '0') {
        NotificationManager.error(responseValidate.Error.message);
      } else {
        handleChangeCenters('couponSpecificLocation', centers);
        onCloseModal();
      }
    } catch (e) {
      console.log(e);
      NotificationManager.error(e.message);
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      console.log(value + name);
    });
  }

  handleChangeCenters(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const {
      onCloseModal,
    } = this.props;
    const {
      centers, regions,
    } = this.state;
    return (
      <div className="theme-light">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title" style={{ textAlign: 'center' }}>
                <h4 className="bold-text">Seleccione una Ubicación Específica</h4>
              </div>
              <form className="form form--horizontal">
                <div className="form__form-group">
                  <div className="form__form-group">
                    <div
                      className="boxed-map"
                      name="zones"
                      component="input"
                      type="text"
                    >
                      <GoogleMap
                        onePoint
                        centers={centers}
                        polygons={regions}
                        submitForm={this.submitForm}
                        onCloseModal={onCloseModal}
                        handleChangeCenters={this.handleChangeCenters}
                      />
                    </div>
                  </div>
                </div>

                <NotificationContainer />
              </form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default reduxForm({
  form: 'pop_up_exchange_center', // a unique identifier for this form
  destroyOnUnmount: true, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(withTranslation('common')(PopupSpecificLocation));
