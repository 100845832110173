import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import PopUp from '../../../shared/components/form/Popup';
import ListItem from './ListItem';
import WizardApiCallEdit from '../../EditionSide/components/WizardApiCallEdit';
import WizardApiCall from './WizardApiCall';
import PopupExchange from '../../../shared/components/form/PopupExchange';

export default class App extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    createAudience: PropTypes.func.isRequired,
    nextPageToCoupons: PropTypes.func.isRequired,
    createZone: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    handleChangeEntity: PropTypes.func.isRequired,
    handleChangeEntityOnModify: PropTypes.func.isRequired,
    handleChangePolygons: PropTypes.func.isRequired,
    campaignId: PropTypes.string.isRequired,
  };

  static modifyAudience(audience) {
    const caller = new WizardApiCall();
    let audM = '';
    try {
      audM = caller.modifyAudience(audience);
      console.log(audM);
    } catch (e) {
      console.log(e);
    }
  }

  // Right way to wait for a promise
  static modifyZone(zone, regions) {
    let zoneM = '';
    const caller = new WizardApiCall();
    try {
      zoneM = caller.modifyZone(zone);
      console.log(zoneM);
    } catch (e) {
      console.log(e);
    }
    return caller.modifyRegions(zone.id, regions);
  }

  static decideGender(publicSexM, publicSexF, publicSexNB) {
    if (publicSexM && publicSexF && publicSexNB) {
      return 0; // todos
    }
    if (publicSexM && !publicSexF && !publicSexNB) {
      return 1;
    }
    if (!publicSexM && publicSexF && !publicSexNB) {
      return 2;
    }
    if (!publicSexM && !publicSexF && publicSexNB) {
      return 3;
    }
    return 0; // check if the audience object in the API should have a gender array
  }

  static reverseGenderDecision(genderId, name) {
    if (genderId === null) {
      return true;
    }
    if (genderId === 1 && name === 'm') {
      return true;
    }
    if (genderId === 2 && name === 'f') {
      return true;
    }
    if (genderId === 3 && name === 'nb') {
      return true;
    }
    if (genderId === null) {
      return true;
    }
    return false;
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modalCentersFlag: false,
      selectedZones: [],
      zoneToModify: {
        zone: {
          id: 0,
        },
      },
      modifying: false,
      duplicate: false,
    };
    this.modifyZoneAudience = this.modifyZoneAudience.bind(this);
    this.onOpenModifyModal = this.onOpenModifyModal.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.createZone = this.createZone.bind(this);
    this.modifyZone = this.modifyZone.bind(this);
    this.modifyAudience = this.modifyAudience.bind(this);
    this.alphaMethod = this.alphaMethod.bind(this);
    this.onOpenDuplicateModal = this.onOpenDuplicateModal.bind(this);
    this.nextPageToCoupons = this.nextPageToCoupons.bind(this);
    this.onOpenCentersModal = this.onOpenCentersModal.bind(this);
    this.onCloseCentersModal = this.onCloseCentersModal.bind(this);
  }

  async componentDidMount() {
    this.alphaMethod();
  }

  onOpenCentersModal() {
    this.setState({ modalCentersFlag: true });
  }

  async onCloseCentersModal() {
    this.setState({ modalCentersFlag: false });
  }

  onOpenModal() {
    this.setState({ open: true, modifying: false });
  }

  onOpenModifyModal() {
    this.setState({ modifying: true, open: true });
  }

  onOpenDuplicateModal() {
    this.setState({ modifying: true, duplicate: true, open: true });
  }

  async onCloseModal() {
    this.setState({
      open: false,
      modifying: false,
      duplicate: false,
      zoneToModify: { zone: { id: 0 } },
    });
  }

  nextPageToCoupons(zoneId) {
    const { nextPageToCoupons } = this.props;
    nextPageToCoupons(zoneId);
  }

  async alphaMethod() {
    const { campaignId } = this.props;
    const caller = new WizardApiCallEdit();
    let audience = {};
    let aud = {};
    let reg = [];
    let response = {};
    const list = [];
    let zResponse = {};
    try {
      zResponse = await caller.getZones(campaignId);
    } catch (e) {
      return;
    }
    const zoneList = zResponse.Content;
    if (zoneList === undefined) {
      return;
    }
    if (zoneList.length === 0) {
      this.setState({ selectedZones: [] });
      return;
    }
    let i = 0;
    zoneList.forEach(async (zoneI) => {
      const responseRegion = await (caller.getRegions(zoneI.id));
      response = await (caller.getAudience(zoneI.id));
      audience = await response.Content;
      const regions = await responseRegion.Content;
      reg = await regions.map(
        c => (
          c.mapRegions.map(
            d => (
              { lat: d.latitude, lng: d.longitude }
            ),
          )
        ),
      );
      aud = {
        id: audience.id,
        name: audience.name,
        publicSexM: App.reverseGenderDecision(audience.genderId, 'm'),
        publicSexF: App.reverseGenderDecision(audience.genderId, 'f'),
        publicSexNB: App.reverseGenderDecision(audience.genderId, 'nb'),
        olderThanAge: { label: audience.olderThanAge, value: audience.olderThanAge },
        youngerThanAge: { label: audience.youngerThanAge, value: audience.youngerThanAge },
        genderId: audience.genderId,
      };
      await list.push({ zone: zoneI, audience: aud, regions: reg });
      await list.sort((a, b) => (b.zone.name < a.zone.name ? 1 : -1));
      i += 1;
      if (i === zoneList.length) {
        this.setState({ selectedZones: list });
      }
    });
  }

  async createZone() {
    const { createZone } = this.props;
    await createZone();
    this.alphaMethod();
  }

  async modifyZone(zn, regions) {
    let mapRegions = [];
    const regionsToModify = [];
    await new Promise(async (resolve) => {
      for (let i = 0; i < regions.length; i += 1) {
        mapRegions = regions[i].map(
          d => (
            { latitude: d.lat, longitude: d.lng }
          ),
        );
        regionsToModify.push({ mapRegions });
        if (i === regions.length - 1) {
          resolve();
        }
      }
    }).then(async () => {
      await App.modifyZone(zn, regionsToModify);
      this.alphaMethod();
    });
  }

  async modifyAudience(aud) {
    await App.modifyAudience(aud);
    this.alphaMethod();
  }

  modifyZoneAudience(id, name) {
    const { selectedZones } = this.state;
    for (let i = 0; i < selectedZones.length; i += 1) {
      if (selectedZones[i].zone.id === id) {
        this.setState({ zoneToModify: selectedZones[i] });
        this.setState({ modifying: true }, () => {
        });
      }
    }
    console.log(name);
  }

  render() {
    const {
      open, zoneToModify, modifying, selectedZones, duplicate, modalCentersFlag,
    } = this.state;
    const {
      handleSubmit, createAudience, previousPage, handleChangeEntity,
      handleChangeEntityOnModify, handleChangePolygons,
    } = this.props;
    return (
      <div className="form__form-group">
        <div className="form__form-group-field boxed-group-field">
          <div
            className="boxed-zones"
            name="zones"
            component="input"
            type="text"
          >
            <div className="boxed-container"> {/* fixing multiline around jsx arrow function */}
              {
                selectedZones
                  .map(
                    c => (
                      <ListItem
                        modify={this.modifyZoneAudience}
                        key={c.zone.id}
                        id={c.zone.id}
                        name={c.zone.name}
                        classSelected={zoneToModify.zone.id === c.zone.id ? 'zoneSelected' : 'normalZone'}
                      />
                    ),
                  )
              }
            </div>
          </div>
          <ButtonToolbar
            className="form__button-toolbar wizard__toolbar toolbarCoupons"
          >
            <Button
              color="primary"
              type="button"
              className="previous btnEvent"
              onClick={this.onOpenModal}
            > Agregar Zona
            </Button>
            <Button
              disabled={!modifying}
              color="primary"
              type="button"
              className="previous btnEvent"
              onClick={this.onOpenModifyModal}
            > Editar Zona
            </Button>
            <Button
              disabled={!modifying}
              color="primary"
              type="button"
              className="previous btnEvent"
              onClick={this.onOpenDuplicateModal}
            > Duplicar Zona
            </Button>
            <Button
              disabled={!modifying}
              color="primary"
              type="button"
              className="previous btnEvent"
              onClick={this.onOpenCentersModal}
            > Grupo de Centros de Canje
            </Button>
          </ButtonToolbar>
          <Modal open={modalCentersFlag} onClose={this.onCloseCentersModal} center>
            <PopupExchange
              onCloseModal={this.onCloseCentersModal}
              zoneToModify={zoneToModify}
            />
          </Modal>
          <Modal open={open} onClose={this.onCloseModal} center>
            <PopUp
              duplicate={duplicate}
              modifyZone={this.modifyZone}
              modifyAudience={this.modifyAudience}
              modifying={modifying}
              zoneToModify={zoneToModify}
              onCloseModal={this.onCloseModal}
              handleSubmit={handleSubmit}
              createAudience={createAudience}
              createZone={this.createZone}
              handleChangeEntity={handleChangeEntity}
              handleChangeCenters={handleChangePolygons}
              handleChangeEntityOnModify={handleChangeEntityOnModify}
            />
          </Modal>
        </div>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={previousPage}> Volver </Button>
          <Button
            disabled={!modifying}
            color="primary"
            type="button"
            className="previous"
            onClick={() => this.nextPageToCoupons(zoneToModify.zone.id)}
            style={{ height: '44px', width: '160px' }}
          > Editar Anuncios
          </Button>
        </ButtonToolbar>
      </div>
    );
  }
}
