import React from 'react'; /* eslint-disable */
import { css } from '@emotion/core';
// First way to import
import {BarLoader, ClimbingBoxLoader, HashLoader} from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class WizardSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.loading,
    }
  }
  render() {
    return (
      <div className='sweet-loading'>
        <BarLoader
          css={override}
          size={50}
          color={'#4ce1b6'}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

export default WizardSpinner;