import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, ButtonToolbar,
} from 'reactstrap';
import Geosuggest from 'react-geosuggest';
import {
  Map, GoogleApiWrapper, Marker, Polygon,
}
  from 'google-maps-react';

const mapStyles = {
  width: '798px',
  height: '366px',
};

const bottomStyles = {
  position: 'absolute',
  top: '410px',
};

class MapContainer extends PureComponent {
  static propTypes = {
    centers: PropTypes.arrayOf(PropTypes.any).isRequired,
    polygons: PropTypes.arrayOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    handleChangeCenters: PropTypes.func.isRequired,
    onePoint: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      centers: [],
      polygons: [],
      pointFromSearchInput: { lat: -31.65464054, lng: -64.67023386 },
    };
    if (props.polygons !== undefined) {
      this.state.polygons = props.polygons;
    }
    if (props.centers !== undefined) {
      this.state.centers = props.centers;
    }
    if (props.centers.length === 1 && props.centers[0].lat) {
      this.state.pointFromSearchInput = { lat: props.centers[0].lat, lng: props.centers[0].lng };
    }
    this.setStateForCentersClick = this.setStateForCentersClick.bind(this);
    this.removePointFromArray = this.removePointFromArray.bind(this);
    this.onInputOptionClick = this.onInputOptionClick.bind(this);
    this.setStateForCentes = this.setStateForCentes.bind(this);
    this.displayMarkers = this.displayMarkers.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.displayMarkersPolygons = this.displayMarkersPolygons.bind(this);
    this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
    this.submitMap = this.submitMap.bind(this);
  }

  onMapClick(t, map, coord) {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setStateForCentersClick(lat, lng);
  }

  onInputOptionClick(suggest) {
    if (suggest !== undefined) {
      this.setStateForCentes(suggest);
    }
  }

  onMarkerDragEnd(t, coord, index) {
    const { handleChangeCenters } = this.props;
    const { centers } = this.state;
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState(() => { centers[index] = { lat, lng }; });
    // this.setStateForCentersClick(lat, lng);
    // this.forceUpdate();
    handleChangeCenters('centers', centers);
  }

  setStateForCentersClick(lat, lng) {
    const { onePoint } = this.props;
    const { centers } = this.state;
    if (!onePoint || (onePoint && centers.length === 0)) {
      this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
      this.setState(previousState => ({
        centers: [...previousState.centers, { lat, lng },
        ],
      }));
    }
    if (onePoint && centers.length === 1) {
      this.setState(() => ({
        centers: [{ lat, lng }],
      }));
    }
    this.setState(() => ({
      pointFromSearchInput: { lat, lng },
    }));
  }

  setStateForCentes(suggest) {
    const { onePoint } = this.props;
    const { centers } = this.state;
    if (!onePoint || (onePoint && centers.length === 0)) {
      this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
      this.setState(previousState => ({
        centers: [...previousState.centers, { lat: suggest.location.lat, lng: suggest.location.lng },
        ],
      }));
    }
    if (onePoint && centers.length === 1) {
      this.setState(() => ({
        centers: [{ lat: suggest.location.lat, lng: suggest.location.lng }],
      }));
    }
    this.setState(() => ({
      pointFromSearchInput: { lat: suggest.location.lat, lng: suggest.location.lng },
    }));
  }

  displayMarkers = () => { /* eslint-disable*/
    const { centers, markerFlag } = this.state;
    return centers.map((store, index) => {
      return <Marker
        hide={markerFlag}
        key={index}
        id={index}
        position={{
          lat: store.lat,
          lng: store.lng
        }}
        draggable
        onDragend={(t, map, coord) => this.onMarkerDragEnd(t, coord, index)}
        onClick={this.removePointFromArray}
      />
    })
  }

  displayMarkersPolygons = () => {
    const { polygons } = this.state;
    let i;
    const list = [];
    for (i = 0; i < polygons.length; i += 1) {
      if (polygons[i].length === 1) {
        list.push({ polygons: polygons[i][0], index: i });
      }
    }
    return list.map((store, index) => /* eslint-disable-next-line */
      (
        <Marker
          icon={{
            url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
          }}
          hide={false} /* eslint-disable-next-line */
          key={index}
          id={index}
          position={{
            lat: store.polygons.lat,
            lng: store.polygons.lng,
          }}
        />
      ));
  };

  async submitMap() {
    const { submitForm } = this.props;
    const { centers } = this.state;
    const { handleChangeCenters } = this.props;
    await handleChangeCenters('centers', centers);
    submitForm();
  }

  removePointFromArray(pointToRemove) {
    const { centers } = this.state;
    let centersAux=[];
    for (let i = 0; i < centers.length; i += 1) {
      if (centers[i].lat !== pointToRemove.position.lat || centers[i].lng !== pointToRemove.position.lng) {
        centersAux.push(centers[i]);
      }
    }
    this.setState({ centers: centersAux });

    this.setState({ markerFlag: true });
    this.displayMarkers();
    const { markerFlag } = this.state;
    this.setState({ markerFlag: false }, () => {
      this.displayMarkers();
    });
  }

  removePointFromArray(pointToRemove) {
    const { centers } = this.state;
    const { handleChangeCenters } = this.props;
    let centersAux = centers;
    for (let i = 0; i < centers.length; i += 1) {
      if (centers[i].lat === pointToRemove.position.lat && centers[i].lng === pointToRemove.position.lng) {
        centersAux.splice(i, 1);
        this.setState({ centers: centersAux }, () => {
          handleChangeCenters('centers', centers);
        });
        this.setState({ markerFlag: true });
        this.displayMarkers();
        break;
      }
    }
    const { markerFlag } = this.state;
    this.setState({ markerFlag: false }, () => {
      this.displayMarkers();
    });
  }

  render() {
    const { onCloseModal } = this.props;
    const {
      pointFromSearchInput, centers, polygons,
    } = this.state;
    const centersPolygon = centers.map( ll => { return { lat: ll.lat, lng: ll.lng }});
    return (
      <div>
        <div>
          <Geosuggest
            style={{
              'input': { maxHeight: '100px' },
              'suggests': { backgroundColor: 'white', zIndex: '99999', position: 'absolute', width: '398px' },
              'suggestItem': {} }}
            placeholder="Buscar lugares..."
            onSuggestSelect={this.onInputOptionClick}
          />
        </div>
        <div>
          <Map /* eslint-disable-next-line */
            google={this.props.google}
            zoom={8}
            style={mapStyles}
            center={{ lat: pointFromSearchInput.lat, lng: pointFromSearchInput.lng }}
            initialCenter={{ lat: pointFromSearchInput.lat, lng: pointFromSearchInput.lng }}
            onClick={this.onMapClick}
          >
            {this.displayMarkers()}
            <Polygon
              paths={centersPolygon}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="#ff0000"
              fillOpacity={0.0}
              onClick={this.onMapClick}
            />
            {polygons
              .map(
                (polygon, index) => (
                    <Polygon
                      key={index}
                      paths={polygon}
                      strokeColor="#0000FF"
                      strokeOpacity={0.8}
                      strokeWeight={2}
                      fillColor="#0000FF"
                      fillOpacity={0.0}
                      onClick={this.onMapClick}
                    />
                ),
              )
            }
            {this.displayMarkersPolygons()}
          </Map>
        </div>
        <Col md={12} lg={12} style={bottomStyles}>
          <ButtonToolbar className="form__button-toolbar btn-center">
            <Button color="primary" type="button" onClick={onCloseModal}>Cancelar</Button>
            <Button color="primary" type="button" onClick={this.submitMap}>Continuar</Button>
          </ButtonToolbar>
        </Col>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.MAP_API_KEY,
})(MapContainer);
