import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ListItem extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    classSelected: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    modify: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }


  onClick() {
    const { ...props } = this.props;
    props.modify(props.id, props.name);
  }

  render() {
    const { name, classSelected } = this.props;
    return (
      <div className={classSelected}>
        <button
          className={classSelected}
          onClick={this.onClick}
          style={{
            cursor: 'pointer', border: 'none', fontWeight: 'bold',
          }}
          type="button"
        >
          {name}
        </button>
      </div>
    );
  }
}
