import React from 'react';
import { Button } from 'reactstrap';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import PopUpAudience from '../../../shared/components/form/PopupAudience';

export default class AudienceForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleChangeCheckBox: PropTypes.func.isRequired,
    handleChangeAudienceAge: PropTypes.func.isRequired,
    handleChangeAudienceLanguage: PropTypes.func.isRequired,
    handleChangeAudienceInterest: PropTypes.func.isRequired,
    handleChangeAudiencePeople: PropTypes.func.isRequired,
    publicName: PropTypes.string.isRequired,
    publicFrom: PropTypes.objectOf(PropTypes.any).isRequired,
    publicLanguage: PropTypes.objectOf(PropTypes.any).isRequired,
    publicInterest: PropTypes.objectOf(PropTypes.any).isRequired,
    publicAgeFrom: PropTypes.objectOf(PropTypes.any).isRequired,
    publicAgeUpTo: PropTypes.objectOf(PropTypes.any).isRequired,
    publicSexM: PropTypes.bool.isRequired,
    publicSexF: PropTypes.bool.isRequired,
    publicSexNB: PropTypes.bool.isRequired,
    agesFrom: PropTypes.arrayOf(PropTypes.object).isRequired,
    agesUpTo: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  state = {
    open: false,
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const {
      handleChangeAudienceInterest, handleChangeAudienceLanguage, handleSubmit, handleChange, handleChangeCheckBox,
      publicName, publicAgeUpTo, publicAgeFrom, handleChangeAudiencePeople, publicFrom, publicSexM, publicSexF,
      publicSexNB, publicInterest, publicLanguage, agesFrom, agesUpTo, handleChangeAudienceAge,
    } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Button
          style={{ width: '50%', padding: '6px' }}
          color="primary"
          type="button"
          className="next"
          onClick={this.onOpenModal}
        > Definir...
        </Button>
        <Modal open={open} onClose={this.onCloseModal} center>
          <PopUpAudience
            agesFrom={agesFrom}
            agesUpTo={agesUpTo}
            handleChangeAudiencePeople={handleChangeAudiencePeople}
            onCloseModal={this.onCloseModal}
            handleSubmit={handleSubmit}
            publicFrom={publicFrom}
            publicSexM={publicSexM}
            publicSexF={publicSexF}
            publicSexNB={publicSexNB}
            handleChange={handleChange}
            publicLanguage={publicLanguage}
            publicInterest={publicInterest}
            handleChangeCheckBox={handleChangeCheckBox}
            handleChangeAudienceInterest={handleChangeAudienceInterest}
            handleChangeAudienceLanguage={handleChangeAudienceLanguage}
            publicName={publicName}
            publicAgeFrom={publicAgeFrom}
            publicAgeUpTo={publicAgeUpTo}
            handleChangeAudienceAge={handleChangeAudienceAge}
          />
        </Modal>
      </div>
    );
  }
}
