import { PureComponent } from 'react';
import { CONTENT_TYPE, AUTHENTICATION_APP, AUTHENTICATION } from '../../../constants';

export default class ApiCall extends PureComponent {
  static fetchData(options, endpoint) {
    return fetch(`${process.env.API_URL}${endpoint}`, options)
      .then(response => response.json());
  }

  constructor(props) {
    super(props);
    this.state = {
      exchangeVoucher: '/exchanges/codes/',
    };
  }

  exchangeRegister(code) {
    const {
      exchangeVoucher,
    } = this.state;
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return ApiCall.fetchData(options, `${exchangeVoucher}${code}`);
  }
}
