import { React, PureComponent } from 'react';
import { CONTENT_TYPE, AUTHENTICATION_APP, AUTHENTICATION } from '../../../constants';

export default class WizardApiCall extends PureComponent {
  static fetchData(options, endpoint) {
    return fetch(`${process.env.API_URL}${endpoint}`, options)
      .then(response => response.json())
      .then((json) => {
        if (json.Error.code !== '0') {
          return json;
          // throw new Error();
        }
        return json;
      });
  }

  constructor(props) {
    super(props);
    this.state = {
      createCampaignEndpoint: '/campaigns',
      createAudienceEndpoint: '/zones/audiences',
      createZoneEndpoint: '/zones',
      createCouponEndpoint: '/coupons',
      createCouponZoneEndpoint: '/zones/coupons/assign',
      modifyCampaignEndpoint: '/campaigns/',
      modifyAudienceEndpoint: '/zones/audiences/',
      modifyZoneEndpoint: '/zones/',
      createExchangeZone: '/exchanges/groups',
      getCampaignGoals: '/campaigns/goals',
      getCampaign: '/campaigns/',
      assignRegionToZone: '/zones/regions/assign',
      validateCorrectLocation: '/zones/',
      getGroups: '/exchanges/zone/',
      getUsersEndpoint: '/exchanges/users',
      createExchangeUser: '/exchanges/user',
      editExchangeUser: '/exchanges/user/',
      deleteExchangeUser: '/exchanges/user/',
      activateCampaignEndpoint: '/campaigns/activate/',
      stopCampaignEndpoint: '/campaigns/stop/',
      getCouponTypes: '/coupons/types',
      getVirtualsEndpoint: '/coupons/virtuals',
    };
  }

  getCouponTypes() {
    const {
      getCouponTypes,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${getCouponTypes}`);
  }

  getVirtuals() {
    const {
      getVirtualsEndpoint,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${getVirtualsEndpoint}`);
  }

  getUsers() {
    const {
      getUsersEndpoint,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${getUsersEndpoint}`);
  }

  getGroupsFromZone(zoneId) {
    const {
      getGroups,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${getGroups}${zoneId}`);
  }

  getCampaign(id) {
    const {
      getCampaign,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${getCampaign}${id}`);
  }

  getCampaignGoals() {
    const {
      getCampaignGoals,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, getCampaignGoals);
  }

  validateCorrectLocation(zoneId, lat, lng) {
    const {
      validateCorrectLocation,
    } = this.state;
    const options = {
      method: 'GET',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall
      .fetchData(options, `${validateCorrectLocation}${zoneId}/regions/contains?latitude=${lat}&longitude=${lng}`);
  }

  createCampaign(campaign) {
    const {
      createCampaignEndpoint,
    } = this.state;
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(campaign),
    };
    return WizardApiCall.fetchData(options, createCampaignEndpoint);
  }

  modifyCampaign(campaign) {
    const {
      modifyCampaignEndpoint,
    } = this.state;
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(campaign),
    };
    return WizardApiCall.fetchData(options, `${modifyCampaignEndpoint}${campaign.id}`);
  }

  createAudience(audience) {
    const {
      createAudienceEndpoint,
    } = this.state;
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(audience),
    };
    return WizardApiCall.fetchData(options, createAudienceEndpoint);
  }

  modifyAudience(audience) {
    const {
      modifyAudienceEndpoint,
    } = this.state;
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(audience),
    };
    return WizardApiCall.fetchData(options, `${modifyAudienceEndpoint}${audience.id}`);
  }

  createZone(zone) {
    const {
      createZoneEndpoint,
    } = this.state;
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(zone),
    };
    return WizardApiCall.fetchData(options, createZoneEndpoint);
  }

  modifyZone(zone) {
    const {
      modifyZoneEndpoint,
    } = this.state;
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(zone),
    };
    return WizardApiCall.fetchData(options, `${modifyZoneEndpoint}${zone.id}`);
  }

  createCoupon(coupon) {
    const {
      createCouponEndpoint,
    } = this.state;
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(coupon),
    };
    return WizardApiCall.fetchData(options, createCouponEndpoint);
  }

  modifyCoupon(coupon) {
    const {
      createCouponEndpoint,
    } = this.state;
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(coupon),
    };
    return WizardApiCall.fetchData(options, `${createCouponEndpoint}/${coupon.id}`);
  }

  deleteCoupon(couponId) {
    const {
      createCouponEndpoint,
    } = this.state;
    const options = {
      method: 'DELETE',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${createCouponEndpoint}/${couponId}`);
  }

  createCouponZone(couponZone) {
    const {
      createCouponZoneEndpoint,
    } = this.state;
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(couponZone),
    };
    return WizardApiCall.fetchData(options, createCouponZoneEndpoint);
  }

  assignFileToCoupon(couponId, assetName, fileToUpload) {
    let finalCall = '';
    const data = new FormData();
    data.append('file', fileToUpload[0]);
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: data,
    };
    switch (assetName) {
      case 'thumbnail':
        finalCall = `/coupons/${couponId}/assets/${assetName}/upload`;
        break;
      case 'texture':
        finalCall = `/coupons/${couponId}/assets/${assetName}/upload`;
        break;
      case 'vimage':
        finalCall = `/coupons/${couponId}/assets/${assetName}/upload`;
        break;
      case 'object':
        finalCall = `/coupons/${couponId}/assets/${assetName}/upload`;
        break;
      case 'material':
        finalCall = `/coupons/${couponId}/assets/${assetName}/upload`;
        break;
      case 'captureSound':
        finalCall = `/coupons/${couponId}/assets/capture_sound/upload`;
        break;
      default:
        console.log('Incorrect file to upload');
    }
    console.log(this);
    return WizardApiCall.fetchData(options, finalCall);
  }

  assignRegionToZone(zoneId, regions) {
    const {
      assignRegionToZone,
    } = this.state;
    const data = { zoneId, mapRegions: regions };
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(data),
    };
    return WizardApiCall.fetchData(options, assignRegionToZone);
  }

  createUser(userName, userPass) {
    const {
      createExchangeUser,
    } = this.state;
    const data = { userName, password: userPass };
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(data),
    };
    return WizardApiCall.fetchData(options, createExchangeUser);
  }

  editUser(userId, userName, userPass) {
    const {
      editExchangeUser,
    } = this.state;
    const data = { userName, password: userPass };
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(data),
    };
    return WizardApiCall.fetchData(options, `${editExchangeUser}${userId}`);
  }

  deleteUser(userId) {
    const {
      deleteExchangeUser,
    } = this.state;
    const options = {
      method: 'DELETE',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${deleteExchangeUser}${userId}`);
  }

  createGroup(zoneId, groups) {
    const {
      createExchangeZone,
    } = this.state;
    const data = { zoneId, mapGroups: groups };
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(data),
    };
    return WizardApiCall.fetchData(options, createExchangeZone);
  }

  createRegions(regions, zoneId) {
    console.log(this);
    const options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(regions),
    };
    return WizardApiCall.fetchData(options, `/zones/${zoneId}/regions`);
  }

  modifyRegions(zoneId, regions) {
    console.log(this);
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(regions),
    };
    return WizardApiCall.fetchData(options, `/zones/${zoneId}/regions`);
  }

  modifyGroup(zoneId, groups) {
    const {
      createExchangeZone,
    } = this.state;
    const data = { zoneId, mapGroups: groups };
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(data),
    };
    return WizardApiCall.fetchData(options, `${createExchangeZone}/edit`);
  }

  deleteGroup(groupId) {
    const {
      createExchangeZone,
    } = this.state;
    const options = {
      method: 'DELETE',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
    };
    return WizardApiCall.fetchData(options, `${createExchangeZone}/${groupId}`);
  }

  activateCampaign(campaign) {
    const {
      activateCampaignEndpoint,
    } = this.state;
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(campaign),
    };
    return WizardApiCall.fetchData(options, `${activateCampaignEndpoint}${campaign.id}`);
  }

  stopCampaign(campaign) {
    const {
      stopCampaignEndpoint,
    } = this.state;
    const options = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
        Authentication: localStorage.getItem(AUTHENTICATION),
      },
      body: JSON.stringify(campaign),
    };
    return WizardApiCall.fetchData(options, `${stopCampaignEndpoint}${campaign.id}`);
  }

  render() {
    return (
      <h1>s</h1>
    );
  }
}
