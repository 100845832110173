import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { changeToDark, changeToLight } = this.props;
    console.log(changeToDark);
    console.log(changeToLight);
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Mi perfil" icon="user" route="/pages/profile" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Estadísticas" icon="chart-bars" route="/pages/statistics" onClick={this.hideSidebar} />
          <SidebarLink title="Nueva Campaña" icon="gift" route="/pages/wizard" onClick={this.hideSidebar} />
          <SidebarLink title="Campañas" icon="gift" route="/pages/campaigns" onClick={this.hideSidebar} />
          <SidebarLink title="Zonas" icon="map" route="/pages/zones" onClick={this.hideSidebar} />
          <SidebarLink title="Anuncios" icon="bullhorn" route="/pages/advertisement" onClick={this.hideSidebar} />

        </ul>
      </div>
    );
  }
}

export default SidebarContent;
