import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import SimpleReactValidator from '../../../shared/simple-react-validator';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { CONTENT_TYPE, AUTHENTICATION_APP, AUTHENTICATION } from '../../../constants';

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  static fetchData(options) { /* eslint-disable-next-line */
    return fetch(`${process.env.API_URL}/users/login`, options)
      .then(response => response.json());
  }

  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          { ` ${message}`}
        </span>
      ),
    });
    this.state = { showPassword: false };
    this.state = { email: '' };
    this.state = { password: '' };
    this.state = { isLogged: false };
    this.state = { errorMessage: false };
    this.callToApiAndGetToken = this.callToApiAndGetToken.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  submitForm() {
    if (this.validator.allValid()) {
      this.callToApiAndGetToken();
    }
    this.validator.showMessages();
    this.forceUpdate();
  }

  async callToApiAndGetToken() {
    const { email } = this.state;
    const { password } = this.state;
    let { options } = this.state;
    const login = {
      email,
      password,
    };
    options = {
      method: 'POST',
      crossDomain: true,
      headers: {
        'Content-Type': CONTENT_TYPE,
        Authentication_app: AUTHENTICATION_APP,
      },
      body: JSON.stringify(login),
    };
    await LogInForm.fetchData(options) /* eslint-disable-next-line */
      .then((response) => {
        if (response.Error.code !== '0') {
          this.setState({ errorMessage: response.Error.message });
        } else {
          localStorage.setItem(AUTHENTICATION, response.Content.userToken);
          this.setState({ errorMessage: '' });
          window.location = '/pages/statistics';
        }
      });
  }

  render() { /* eslint-disable-next-line */
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;
    const { email } = this.state;
    const { password } = this.state;/* eslint-disable-next-line */
    const { isLogged } = this.state;
    const { errorMessage } = this.state;
    return (

      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <input
              name="email"
              type="email"
              placeholder="E-mail"
              className="form-control"
              value={email || ''}
              onChange={this.handleChangeEmail}
            />
          </div>
          {this.validator.message('email', email, 'required|email')}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Contraseña</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              component="input"
              className="form-control"
              type={showPassword ? 'text' : 'password'}
              placeholder="Contraseña"
              value={password || ''}
              onChange={this.handleChangePassword}
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          {this.validator.message('password', password, 'required')}
          <div className="account__forgot-password">
            <a href="/">¿Olvidó su contraseña?</a>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Recordarme"
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary account__btn account__btn--small"
          onClick={() => { /* eslint-disable-next-line */
            this.callToApiAndGetToken(); /* eslint-disable-next-line */
          }}
        >Ingresar
        </button>
        <span style={{ color: 'red', height: 20 }}>{errorMessage}</span>
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form',
})(LogInForm);
