import React from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Row, Col,
} from 'reactstrap';
import classnames from 'classnames';
import StatisticsGraph from './StatisticsGraph';
import StatisticsCalendar from './StatisticsCalendar';
import WizardApiCallEdit from '../../EditionSide/components/WizardApiCallEdit';

export default class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      activeTabVertical: 0,
      activeTabVerticalPrint: 0,
      activeTabAllVertical: 'all',
      campaigns: [],
      stats: [],
      statsPrint: [],
      dateRange: 'lm',
    };

    this.toggle = this.toggle.bind(this);
    this.toggleVertical = this.toggleVertical.bind(this);
    this.toggleVerticalPrint = this.toggleVerticalPrint.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeCampaignDateStats = this.handleChangeCampaignDateStats.bind(this);
    this.handleChangeCampaignDateStatsPrint = this.handleChangeCampaignDateStatsPrint.bind(this);
  }

  async componentDidMount() {
    let campaigns = [];
    const caller = new WizardApiCallEdit();
    try {
      campaigns = await caller.getCampaigns();
    } catch (e) {
      console.log(e);
      return;
    }

    this.setState({ campaigns: campaigns.Content });

    this.handleChangeCampaignDateStats();
    this.handleChangeCampaignDateStatsPrint();
  }

  async handleChangeCampaignDateStatsPrint() {
    let stats = [];
    let campaignId = 'all';

    const caller = new WizardApiCallEdit();

    const { activeTabVerticalPrint, dateRange } = this.state;

    if (activeTabVerticalPrint === 0) {
      campaignId = 'all';
    } else {
      campaignId = activeTabVerticalPrint;
    }
    try {
      let statsResponse = await caller.getCampaignStats(campaignId, dateRange, 'print');
      statsResponse = statsResponse.Content;
      if (statsResponse.length > 0) {
        statsResponse
          .map(/* eslint-disable-next-line */
            c => (
              c.days.map(d => (stats.push(d)))
            ),
          );
      }
    } catch (e) {
      stats = [];
      console.log(e);
    }
    this.setState({ statsPrint: stats });
  }

  async handleChangeCampaignDateStats() {
    let stats = [];
    let campaignId = 'all';

    const caller = new WizardApiCallEdit();

    const { activeTabVertical, dateRange } = this.state;

    if (activeTabVertical === 0) {
      campaignId = 'all';
    } else {
      campaignId = activeTabVertical;
    }
    try {
      let statsResponse = await caller.getCampaignStats(campaignId, dateRange, 'reach');
      statsResponse = statsResponse.Content;
      if (statsResponse.length > 0) {
        statsResponse
          .map(/* eslint-disable-next-line */
            c => (
              c.days.map(d => (stats.push(d)))
            ),
          );
      }
    } catch (e) {
      stats = [];
      console.log(e);
    }

    this.setState({ stats });
  }

  handleChangeDate(value) {
    this.setState({ dateRange: value }, () => {
      this.handleChangeCampaignDateStats();
      this.handleChangeCampaignDateStatsPrint();
    });
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  toggleVertical(tab) {
    const { activeTabVertical } = this.state;
    if (activeTabVertical !== tab) {
      this.setState({
        activeTabVertical: tab,
      }, () => {
        this.handleChangeCampaignDateStats();
      });
    }
  }

  toggleVerticalPrint(tab) {
    const { activeTabVerticalPrint } = this.state;
    if (activeTabVerticalPrint !== tab) {
      this.setState({
        activeTabVerticalPrint: tab,
      }, () => {
        this.handleChangeCampaignDateStatsPrint();
      });
    }
  }

  render() {
    const {
      activeTab, activeTabVertical, activeTabAllVertical, activeTabVerticalPrint, campaigns, stats, statsPrint,
    } = this.state;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <Row>
              <Col sm={12} md={7}>
                <Nav tabs className="navtabs_stats">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      Alcance
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      Importe Gastado
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { this.toggle('3'); }}
                    >
                      Impresiones
                    </NavLink>
                  </NavItem>

                </Nav>
              </Col>
              <Col sm={12} md={5}>
                <StatisticsCalendar
                  handleChangeDate={this.handleChangeDate}
                />
              </Col>
            </Row>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="stadistics_graph">
                  <Row>
                    <Col sm={12} md={3} className="stadistics_graph_col_left">
                      <Nav tabs vertical>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTabVertical === 0 })}
                            onClick={() => { this.toggleVertical(0); }}
                          >
                            Todas las campañas
                          </NavLink>
                        </NavItem>
                        {
                          campaigns
                            .map(
                              (c, index) => (/* eslint-disable-next-line */
                                <NavItem key={index}>
                                  <NavLink

                                    className={classnames({ active: activeTabVertical === c.id })}
                                    onClick={() => { this.toggleVertical(c.id); }}
                                  >
                                    {c.name}
                                  </NavLink>
                                </NavItem>

                              ),
                            )
                        }

                      </Nav>
                    </Col>
                    <Col sm={12} md={9} className="stadistics_graph_col_right">
                      <TabContent activeTab={activeTabAllVertical}>
                        <TabPane tabId="all">
                          <Row>
                            <Col sm="12">
                              <StatisticsGraph
                                stats={stats}
                              />
                            </Col>
                          </Row>
                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <h4>Tab 2 Contents</h4>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <div className="stadistics_graph">
                  <Row>
                    <Col md={3} className="stadistics_graph_col_left">
                      <Nav tabs vertical>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTabVerticalPrint === 0 })}
                            onClick={() => { this.toggleVerticalPrint(0); }}
                          >
                            Todas las campañas
                          </NavLink>
                        </NavItem>
                        {
                          campaigns
                            .map(
                              (c, index) => (/* eslint-disable-next-line */
                                <NavItem key={index}>
                                  <NavLink

                                    className={classnames({ active: activeTabVerticalPrint === c.id })}
                                    onClick={() => { this.toggleVerticalPrint(c.id); }}
                                  >
                                    {c.name}
                                  </NavLink>
                                </NavItem>

                              ),
                            )
                        }

                      </Nav>
                    </Col>
                    <Col md={9} className="stadistics_graph_col_right">
                      <TabContent activeTab={activeTabAllVertical}>
                        <TabPane tabId="all">
                          <Row>
                            <Col sm="12">
                              <StatisticsGraph
                                stats={statsPrint}
                              />
                            </Col>
                          </Row>
                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
