import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'rc-time-picker/assets/index.css';
import ReactRadioButtonGroup from 'react-radio-button-group';
import Modal from 'react-responsive-modal';
import swal from '@sweetalert/with-react';
import PopupSpecificLocation from '../../../shared/components/form/PopupSpecificLocation';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import UploadFilesForm from './PopupjsUploadFiles';
import SimpleReactValidator from '../../../shared/simple-react-validator';
import WizardSpinner from '../../../shared/components/form/WizardSpinner';
import WizardApiCall from './WizardApiCall';
import WizardApiCallEdit from '../../EditionSide/components/WizardApiCallEdit';
import ListItemChk from './ListItemChk';

class WizardFormThree extends PureComponent {
  static propTypes = {
    selectedZoneId: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    handleChangeEntity: PropTypes.func.isRequired,
    duplicate: PropTypes.bool,
    coupon: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    duplicate: false,
  };

  constructor(props) {
    super(props);
    if (Object.keys(props.coupon).length) {
      this.state = {
        id: props.coupon.id,
        showCoordButton: !!+props.coupon.randomLocation,
        announcementName: props.coupon.name,
        sku: props.coupon.sku,
        promotionName: props.coupon.promotionName,
        amount: props.coupon.quantity,
        uniqueByUser: !!+props.coupon.uniqueByUser,
        recaptureTime: props.coupon.recaptureTime,
        location: props.coupon.randomLocation ? 'Aleatoria dentro de la geocerca' : 'Específica',
        entireDescription: props.coupon.description,
        termsAndCond: props.coupon.termsAndConditions,
        validity: props.coupon.validity,
        showSequenceParts: !props.coupon.sequence,
        sequence: !!+props.coupon.sequence,
        sequencePartsTotal: props.coupon.sequencePartsTotal,
        couponTypeId: props.coupon.typeId,
        rotationSpeed: props.coupon.rotationSpeed,
        huntFrom: props.coupon.huntFrom,
        huntTo: props.coupon.huntTo,
        seeFrom: props.coupon.seeFrom,
        seeTo: props.coupon.seeTo,
        touchFrom: props.coupon.touchFrom,
        touchTo: props.coupon.touchTo,
        afterCampaign: !!+props.coupon.validAfterCampaign,
        // files
        object: '',
        material: '',
        texture: '',
        vimage: '',
        thumbnail: '',
        captureSound: '',
        // exchangeCenter
        modalLocationFlag: false,
        modalSpinnerFlag: false,
        couponSpecificLocation: [{ lat: props.coupon.latitude, lng: props.coupon.longitude }],
        objectURL: props.coupon.object,
        materialURL: props.coupon.material,
        textureURL: props.coupon.texture,
        vimageURL: props.coupon.vimage,
        thumbnailURL: props.coupon.thumbnail,
        captureSoundURL: props.coupon.captureSound,
        onFinishNavigateTo: '/pages/campaigns',
        typeList: [],
        couponType: {
          label: 'Select',
          value: 0,
        },
        selectedVirtuals: [],
        virtuals: [],
        showFeaturesVirtuals: props.coupon.typeId === 2 ? 0 : 1,
      };
    } else {
      this.state = {
        id: 0,
        showCoordButton: true,
        announcementName: '',
        sku: '',
        promotionName: '',
        amount: '',
        uniqueByUser: false,
        recaptureTime: '',
        location: 'Aleatoria dentro de la geocerca',
        entireDescription: '',
        termsAndCond: '',
        validity: '',
        showSequenceParts: true,
        sequence: false,
        sequencePartsTotal: '',
        couponTypeId: '',
        rotationSpeed: '',
        huntFrom: '',
        huntTo: '',
        seeFrom: '',
        seeTo: '',
        touchFrom: '',
        touchTo: '',
        afterCampaign: false,
        // files
        object: '',
        material: '',
        texture: '',
        vimage: '',
        thumbnail: '',
        captureSound: '',
        // exchangeCenter
        modalLocationFlag: false,
        modalSpinnerFlag: false,
        couponSpecificLocation: [{}],
        objectURL: '',
        materialURL: '',
        textureURL: '',
        vimageURL: '',
        thumbnailURL: '',
        captureSoundURL: '',
        onFinishNavigateTo: '/pages/campaigns',
        typeList: [],
        couponType: {
          label: 'Select',
          value: 0,
        },
        selectedVirtuals: [],
        virtuals: [],
        showFeaturesVirtuals: 1,
      };
    }
    if (props.coupon.features) {
      this.state.selectedVirtuals = props.coupon.features;
    }
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    this.handleChangeRadioButton = this.handleChangeRadioButton.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.saveState = this.saveState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.handleChangeEntity = this.handleChangeEntity.bind(this);
    this.onOpenLocationModal = this.onOpenLocationModal.bind(this);
    this.onCloseLocationModal = this.onCloseLocationModal.bind(this);
    this.onOpenSpinnerModal = this.onOpenSpinnerModal.bind(this);
    this.onCloseSpinnerModal = this.onCloseSpinnerModal.bind(this);
    this.alphaMethod = this.alphaMethod.bind(this);
    this.handleChangeSequence = this.handleChangeSequence.bind(this);
    this.typeList = this.typeList.bind(this);
    this.handleChangeSelectType = this.handleChangeSelectType.bind(this);
    this.IsSelectedVirtual = this.IsSelectedVirtual.bind(this);
    this.virtualsList = this.virtualsList.bind(this);
    this.modifyChk = this.modifyChk.bind(this);
  }

  async componentDidMount() {
    this.typeList();
    this.virtualsList();
  }

  onOpenLocationModal() {
    this.setState({ modalLocationFlag: true });
  }

  async onCloseLocationModal() {
    this.setState({ modalLocationFlag: false });
  }

  onOpenSpinnerModal() {
    this.setState({ modalSpinnerFlag: true });
  }

  async onCloseSpinnerModal() {
    this.setState({ modalSpinnerFlag: false });
  }

  async alphaMethod() {
    const { selectedZoneId } = this.props;
    const caller = new WizardApiCallEdit();
    let reg = [];
    const responseRegion = await caller.getRegions(selectedZoneId);
    const region = await responseRegion.Content;
    reg = await region.map(
      c => (
        c.mapRegions.map(
          d => (
            { lat: d.latitude, lng: d.longitude }
          ),
        )
      ),
    );

    this.setState({ regions: reg });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChangeEntity(name, value) {
    const { handleChangeEntity } = this.props;
    this.setState({ [name]: value });
    handleChangeEntity(name, value);
  }

  handleChangeRadioButton(event) {
    this.setState({ location: event }, () => {
      const { location } = this.state;
      if (location === 'Específica') {
        this.setState({ showCoordButton: false }, () => {
        });
      } else {
        this.setState({ showCoordButton: true }, () => {
        });
      }
    });
  }

  handleChangeCheckBox(section, event) {
    if (event.target === undefined) {
      if (event.checked !== undefined) {
        this.setState({ [section]: event.checked }, () => {
          if (section === 'sequence') {
            this.handleChangeSequence();
          }
        });
      }
    } else {
      this.setState({ [section]: event.target.checked }, () => {
        if (section === 'sequence') {
          this.handleChangeSequence();
        }
      });
    }
  }

  handleChangeSequence() {
    const {
      sequence,
    } = this.state;
    if (sequence) {
      this.setState({ showSequenceParts: false });
    } else {
      this.setState({ showSequenceParts: true });
    }
  }

  handleChangeFiles(fileName, fileValue) {
    this.setState({ [fileName]: fileValue });
  }

  async handleSubmit() {
    let message = '';
    const {
      onFinishNavigateTo, id, announcementName, sku, promotionName, amount,
      recaptureTime, uniqueByUser, entireDescription, termsAndCond, validity, afterCampaign, texture, vimage, thumbnail,
      object, material, captureSound, couponSpecificLocation, showCoordButton, rotationSpeed, selectedVirtuals,
      huntFrom, huntTo, seeFrom, seeTo, touchFrom, touchTo, sequence, sequencePartsTotal, couponTypeId,
    } = this.state;
    const {
      selectedZoneId, duplicate,
    } = this.props;
    const couponToCreate = {
      id,
      name: announcementName,
      sku,
      promotionName,
      quantity: parseInt(amount, 10),
      recaptureTime: parseInt(recaptureTime, 10),
      uniqueByUser: uniqueByUser ? 1 : 0,
      description: entireDescription,
      termsAndConditions: termsAndCond,
      validity: parseInt(validity, 10),
      sequence: sequence ? 1 : 0,
      sequencePartsTotal: sequence ? parseInt(sequencePartsTotal, 10) : 0,
      typeId: couponTypeId,
      rotation: rotationSpeed > 0 ? 1 : 0,
      rotationSpeed: parseInt(rotationSpeed, 10),
      huntFrom: huntFrom === '' ? 0 : parseInt(huntFrom, 10),
      huntTo: huntTo === '' ? 0 : parseInt(huntTo, 10),
      seeFrom: seeFrom === '' ? 0 : parseInt(seeFrom, 10),
      seeTo: seeTo === '' ? 0 : parseInt(seeTo, 10),
      touchFrom: touchFrom === '' ? 0 : parseInt(touchFrom, 10),
      touchTo: touchTo === '' ? 0 : parseInt(touchTo, 10),
      validAfterCampaign: afterCampaign ? 1 : 0,
      randomLocation: showCoordButton ? 1 : 0,
      latitude: !showCoordButton && couponSpecificLocation[0].lat ? couponSpecificLocation[0].lat : 0,
      longitude: !showCoordButton && couponSpecificLocation[0].lng ? couponSpecificLocation[0].lng : 0,
      features: couponTypeId === 2 ? selectedVirtuals : [],
    };
    const couponZone = {
      couponId: '',
      zoneId: '',
    };
    const caller = new WizardApiCall();
    let couponId = '';
    try {
      if (!id || id === 0 || duplicate) {
        couponId = await caller.createCoupon(couponToCreate);
        couponZone.couponId = parseInt(couponId.Content.CouponId, 10);
        couponZone.zoneId = parseInt(selectedZoneId, 10);
        await caller.createCouponZone(couponZone);
        message = 'Cupón creado correctamente';
        this.setState({ id: couponZone.couponId });
      } else if (!duplicate) {
        await caller.modifyCoupon(couponToCreate);
        message = 'Cupón editado correctamente';
        couponZone.couponId = couponToCreate.id;
      }
    } catch (e) {
      console.log(e.message);
      return;
    }
    this.setState({ modalSpinnerFlag: true });
    this.onOpenSpinnerModal();
    try {
      let z = {};
      let error = false;
      if (thumbnail !== '') {
        z = await caller.assignFileToCoupon(couponZone.couponId, 'thumbnail', thumbnail);
        if (z.Error.code !== '0') {
          this.setState({ modalSpinnerFlag: false });
          swal({
            title: '¡No se pudo subir archivo Thumbnail!',
            text: z.Error.message,
            icon: 'error',
            button: 'OK',
          });
          error = true;
        }
      }
      if (vimage !== '' && error === false) {
        z = await caller.assignFileToCoupon(couponZone.couponId, 'vimage', vimage);
        if (z.Error.code !== '0') {
          this.setState({ modalSpinnerFlag: false });
          swal({
            title: '¡No se pudo subir archivo Imagen Voucher!',
            text: z.Error.message,
            icon: 'error',
            button: 'OK',
          });
          error = true;
        }
      }
      if (object !== '' && error === false) {
        z = await caller.assignFileToCoupon(couponZone.couponId, 'object', object);
        if (z.Error.code !== '0') {
          this.setState({ modalSpinnerFlag: false });
          swal({
            title: '¡No se pudo subir archivo Objeto!',
            text: z.Error.message,
            icon: 'error',
            button: 'OK',
          });
          error = true;
        }
      }
      if (captureSound !== '' && error === false) {
        z = await caller.assignFileToCoupon(couponZone.couponId, 'captureSound', captureSound);
        if (z.Error.code !== '0') {
          this.setState({ modalSpinnerFlag: false });
          swal({
            title: '¡No se pudo subir archivo Sonido!',
            text: z.Error.message,
            icon: 'error',
            button: 'OK',
          });
          error = true;
        }
      }
      if (texture !== '' && error === false) {
        z = await caller.assignFileToCoupon(couponZone.couponId, 'texture', texture);
        if (z.Error.code !== '0') {
          this.setState({ modalSpinnerFlag: false });
          swal({
            title: '¡No se pudo subir archivo Textura!',
            text: z.Error.message,
            icon: 'error',
            button: 'OK',
          });
          error = true;
        }
      }
      if (material !== '' && error === false) {
        z = await caller.assignFileToCoupon(couponZone.couponId, 'material', material);
        if (z.Error.code !== '0') {
          this.setState({ modalSpinnerFlag: false });
          swal({
            title: '¡No se pudo subir archivo Material!',
            text: z.Error.message,
            icon: 'error',
            button: 'OK',
          });
          error = true;
        }
      }
      if (error === false) {
        this.setState({ modalSpinnerFlag: false });
        swal({
          title: message,
          icon: 'success',
          button: 'OK',
        }).then(() => {
          window.location = onFinishNavigateTo;
        });
      }
    } catch (e) {
      console.log('error', e.message);
      this.setState({ modalSpinnerFlag: false });
    }
  }

  previousPage() {
    const { previousPage } = this.props;
    this.saveState();
    previousPage();
  }

  saveState() {
    const { handleChangeEntity } = this.props;
    const {
      announcementName, sku, promotionName, amount, entireDescription, termsAndCond, recaptureTime, validity,
      uniqueByUser, afterCampaign, texture, vimage, thumbnail, object, material, captureSound, location,
      couponSpecificLocation, id, rotationSpeed, huntFrom, huntTo, seeFrom, seeTo, touchFrom, touchTo,
      sequence, sequencePartsTotal, couponTypeId,
    } = this.state;
    const cp = {
      id,
      announcementName,
      sku,
      promotionName,
      amount,
      uniqueByUser,
      recaptureTime,
      location,
      entireDescription,
      termsAndCond,
      validity,
      sequence,
      sequencePartsTotal,
      couponTypeId,
      rotationSpeed,
      huntFrom,
      huntTo,
      seeFrom,
      seeTo,
      touchFrom,
      touchTo,
      afterCampaign,
      // files
      object,
      material,
      texture,
      vimage,
      thumbnail,
      captureSound,
    };
    handleChangeEntity('coupon', cp);
    handleChangeEntity('couponSpecificLocation', couponSpecificLocation);
  }

  async submitForm() {
    this.validator.showMessages();
    this.forceUpdate();
    if (this.validator.allValid()) {
      await this.saveState();
      this.handleSubmit();
    }
  }

  async typeList() { /* eslint-disable */
    const caller = new WizardApiCall();
    let promise = {};
    try {
      promise = await caller.getCouponTypes();
    } catch (e) {
      console.log(e);
      return;
    }
    if (promise.Content) {
      const list = promise.Content;
      await list.sort((a, b) => (b.name < a.name ? 1 : -1));
      let cboType = [];
      list.map((c) => {
        if (c.id === this.props.coupon.typeId) {
          this.setState({couponType: {label: c.name, value: c.id}})
        }
        cboType.push({label: c.name, value: c.id})
      });
      this.handleChangeEntity('typeList', cboType);
    }
  }

  async virtualsList() {
    const caller = new WizardApiCall();
    let promise = {};
    try {
      promise = await caller.getVirtuals();
    } catch (e) {
      console.log(e);
      return;
    }
    if (promise.Content) {
      const list = promise.Content;
      await list.sort((a, b) => (b.userName < a.userName ? 1 : -1));
      this.handleChangeEntity('virtuals', list);
    }
  }

  handleChangeSelectType(selected) {
    this.setState({couponType: {value: selected.value, label: selected.label}});
    this.setState({couponTypeId: selected.value});
    if (selected.value === 2) {
      this.setState({showFeaturesVirtuals: 0});
    } else {
      this.setState({showFeaturesVirtuals: 1});
    }
  }

  modifyChk(id, valueChk) {
    const {selectedVirtuals, virtuals} = this.state;
    if (valueChk) {
      for (let i = 0; i < virtuals.length; i += 1) {
        if (virtuals[i].id === id) {
          const newData = [
            ...selectedVirtuals,
            virtuals[i],
          ];
          this.setState({selectedVirtuals: newData});
        }
      }
    } else {
      const newData = [];
      for (let i = 0; i < selectedVirtuals.length; i += 1) {
        if (selectedVirtuals[i].id !== id) {
          newData.push(selectedVirtuals[i]);
        }
      }
      this.setState({selectedVirtuals: newData});
    }
  }

  IsSelectedVirtual(id) {
    const {
      selectedVirtuals,
    } = this.state;
    let rtdo = false;
    if (selectedVirtuals.length > 0) {
      for (let i = 0; i < selectedVirtuals.length; i += 1) {
        if (selectedVirtuals[i].id === id) {
          rtdo = true;
        }
      }
    }
    return rtdo;
  }

  render() {
    const {
      handleSubmit, selectedZoneId,
    } = this.props;

    const {
      announcementName, sku, promotionName, amount, entireDescription, termsAndCond, recaptureTime, validity,
      uniqueByUser, afterCampaign, location, showCoordButton, modalLocationFlag, showFeaturesVirtuals,
      couponSpecificLocation, textureURL, vimageURL, thumbnailURL, objectURL, materialURL, captureSoundURL,
      regions, modalSpinnerFlag, rotationSpeed, huntFrom, huntTo, seeFrom, seeTo, touchFrom, touchTo, sequence,
      sequencePartsTotal, showSequenceParts, couponType, typeList, couponTypeId, virtuals, selectedVirtuals,
    } = this.state;
    return (
      <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
        <h3 className="wizard__title">Anuncio</h3>
        <div className="form__form-group">
          <span className="form__form-group-label">Nombre del Anuncio</span>
          <div className="form__form-group-field">
            <input
              name="announcementName"
              component="input"
              type="text"
              placeholder="nombre"
              onChange={this.handleChange}
              value={announcementName}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('nombre', announcementName, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">SKU</span>
          <div className="form__form-group-field">
            <input
              name="sku"
              component="input"
              type="text"
              placeholder="sku"
              onChange={this.handleChange}
              value={sku}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('sku', sku, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Nombre de la Promoción</span>
          <div className="form__form-group-field">
            <input
              name="promotionName"
              component="input"
              type="text"
              placeholder="nombre de promoción"
              onChange={this.handleChange}
              value={promotionName}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('nombre', promotionName, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Cantidad</span>
          <div className="form__form-group-field">
            <input
              name="amount"
              component="input"
              type="number"
              min={0}
              placeholder="cantidad"
              onChange={this.handleChange}
              value={amount}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('cantidad', amount, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Único por usuario</span>
          <div className="form__form-group-field">
            <Field
              defaultChecked={uniqueByUser}
              type="checkbox"
              name="uniqueByUser"
              component={renderCheckBoxField}
              onChange={e => this.handleChangeCheckBox('uniqueByUser', e)}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tiempo de Recaptura</span>
          <div className="form__form-group-field">
            <input
              name="recaptureTime"
              component="input"
              type="number"
              min={0}
              placeholder="tiempo en segundos"
              onChange={this.handleChange}
              value={recaptureTime}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('tiempo de recaptura', recaptureTime, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Ubicación</span>
          <div className="form__form-group-field">
            <Button
              style={{height: '32px', width: '32', padding: '0px 25px'}}
              color="primary"
              type="button"
              className="previous"
              disabled={showCoordButton}
              onClick={this.onOpenLocationModal}
            >Seleccionar coordenadas
            </Button>
          </div>
          <Modal
            classNames={{modal: 'modal_mapa'}}
            open={modalLocationFlag}
            onClose={this.onCloseLocationModal}
            center
          >
            <PopupSpecificLocation
              zoneId={selectedZoneId}
              onCloseModal={this.onCloseLocationModal}
              handleChangeCenters={(n, e) => this.handleChangeEntity('couponSpecificLocation', e)}
              onePoint
              centers={couponSpecificLocation}
              regions={regions}
            />
          </Modal>
          <div className="form__form-group-field">
            <ReactRadioButtonGroup
              name="location"
              options={[
                {label: 'Específica', value: 'Específica'},
                {
                  label: 'Aleatoria dentro de la geocerca',
                  value: 'Aleatoria dentro de la geocerca',
                  itemClassName: 'radio-button-group',
                },
              ]}
              value={location}
              onChange={(e) => {
                this.handleChangeRadioButton(e);
              }}
              groupClassName="form__form-group"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Descripción Completa del descuento</span>
          <div className="form__form-group-field">
            <textarea
              style={{minHeight: '35px', marginBottom: '0px'}}
              name="entireDescription"
              component="input"
              type="text"
              maxLength={150}
              placeholder="descripción"
              onChange={this.handleChange}
              value={entireDescription}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('descripción completa', entireDescription, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Bases y Condiciones</span>
          <div className="form__form-group-field">
            <textarea
              name="termsAndCond"
              component="input"
              type="text"
              placeholder="bases y condiciones"
              onChange={this.handleChange}
              value={termsAndCond}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('bases y condiciones', termsAndCond, 'required')}
          </div>
        </div>
        <WizardSpinner loading={false}/>
        <div className="form__form-group">
          <span className="form__form-group-label">Vigencia una vez capturado</span>
          <div className="form__form-group-field">
            <input
              name="validity"
              component="input"
              type="number"
              min={0}
              placeholder="vigencia desde captura en días"
              onChange={this.handleChange}
              value={validity}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('vigencia', validity, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Canjeable después de fin de Campaña</span>
          <div className="form__form-group-field">
            <Field
              defaultChecked={afterCampaign}
              type="checkbox"
              name="afterCampaign"
              component={renderCheckBoxField}
              onChange={e => this.handleChangeCheckBox('afterCampaign', e)}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tipo</span>
          <div className="form__form-group-field">
            <Select
              options={typeList}
              className="form__form-group-input-wrap-own selectTypeCoupon"
              classNamePrefix="select"
              onChange={this.handleChangeSelectType}
              name="couponTypeId"
              value={couponType}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('Tipo', couponTypeId, 'required')}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Caracteristicas virtual</span>
          <div className="form__form-group-field">
            <div
              className="boxed boxFeaturesVirtuals"
              disabled={showFeaturesVirtuals}
              name="zones"
              component="input"
              type="text"
            >
              <div className="boxed-container">
                {
                  virtuals.map((c, index) => { /* eslint-disable */
                    let value = this.IsSelectedVirtual(c.id);
                    return (<ListItemChk
                      modify={this.modifyChk}
                      selectedVirtuals={selectedVirtuals}
                      key={index}
                      id={c.id}
                      name={c.name}
                      description={c.description}
                      classSelected='normalZone'
                      value={value}
                      showFeaturesVirtuals={showFeaturesVirtuals}
                    />)
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Subir Archivos</span>
          <div className="form__form-group-field">
            <UploadFilesForm
              object={objectURL}
              material={materialURL}
              texture={textureURL}
              vimage={vimageURL}
              thumbnail={thumbnailURL}
              captureSound={captureSoundURL}
              handleSubmit={handleSubmit}
              handleChangeFiles={this.handleChangeFiles}
            />
          </div>
        </div>
        <h4><b>Secuencias</b></h4><br/><br/>
        <div className="form__form-group marginLeft20">
          <span className="form__form-group-label">habilitar secuencia</span>
          <div className="form__form-group-field">
            <Field
              defaultChecked={sequence}
              type="checkbox"
              name="sequence"
              component={renderCheckBoxField}
              onChange={e => this.handleChangeCheckBox('sequence', e)}
            />
          </div>
        </div>
        <div className="form__form-group marginLeft20">
          <span className="form__form-group-label">Cantidad de partes para completar</span>
          <div className="form__form-group-field">
            <input
              name="sequencePartsTotal"
              component="input"
              type="number"
              min={0}
              placeholder="Cantidad de partes para completar"
              disabled={showSequenceParts}
              onChange={this.handleChange}
              value={sequencePartsTotal}
            />
          </div>
        </div>

        <h4><b>Animaciones</b></h4><br/><br/>
        <div className="form__form-group marginLeft20">
          <span className="form__form-group-label">Velocidad de rotación</span>
          <div className="form__form-group-field">
            <input
              name="rotationSpeed"
              component="input"
              type="number"
              min={0}
              placeholder="velocidad de rotación"
              onChange={this.handleChange}
              value={rotationSpeed}
            />
          </div>
          <div className="form__form-group-field">
            {this.validator.message('velocidad de rotación', rotationSpeed, 'required')}
          </div>
        </div>
        <h5 className="marginLeft20"><b>Cazar</b></h5>
        <div className="form__form-group marginLeft40">
          <span className="form__form-group-label">Desde</span>
          <div className="form__form-group-field">
            <input
              name="huntFrom"
              component="input"
              type="number"
              min={0}
              placeholder="Desde"
              onChange={this.handleChange}
              value={huntFrom}
            />
            <span className="form__form-group-label">Hasta</span>
            <input
              name="huntTo"
              component="input"
              type="number"
              min={0}
              placeholder="Hasta"
              onChange={this.handleChange}
              value={huntTo}
            />
          </div>
        </div>
        <h5 className="marginLeft20"><b>Visualizar</b></h5>
        <div className="form__form-group marginLeft40">
          <span className="form__form-group-label">Desde</span>
          <div className="form__form-group-field">
            <input
              name="seeFrom"
              component="input"
              type="number"
              min={0}
              placeholder="Desde"
              onChange={this.handleChange}
              value={seeFrom}
            />
            <span className="form__form-group-label">Hasta</span>
            <input
              name="seeTo"
              component="input"
              type="number"
              min={0}
              placeholder="Hasta"
              onChange={this.handleChange}
              value={seeTo}
            />
          </div>
        </div>
        <h5 className="marginLeft20"><b>Tocar</b></h5>
        <div className="form__form-group marginLeft40">
          <span className="form__form-group-label">Desde</span>
          <div className="form__form-group-field">
            <input
              name="touchFrom"
              component="input"
              type="number"
              min={0}
              placeholder="Desde"
              onChange={this.handleChange}
              value={touchFrom}
            />
            <span className="form__form-group-label">Hasta</span>
            <input
              name="touchTo"
              component="input"
              type="number"
              min={0}
              placeholder="Hasta"
              onChange={this.handleChange}
              value={touchTo}
            />
          </div>
        </div>
        <Modal closeIconSize={1} open={modalSpinnerFlag} onClose={this.onCloseSpinnerModal} center>
          <div className="boxed-container">
            <span className="form__form-group-label">Subiendo Archivos</span>
            <div className="form__form-group-field">
              <WizardSpinner/>
            </div>
          </div>
        </Modal>
        <ButtonToolbar className="form__button-toolbar wizard__toolbar">
          <Button color="primary" type="button" className="previous" onClick={this.previousPage}>Volver</Button>
          <Button color="primary" type="button" onClick={this.submitForm} className="next">Guardar</Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(WizardFormThree);
