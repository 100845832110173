import React, { Component } from 'react';
import {
  FormGroup, Label, Input, Col,
} from 'reactstrap';
import PropTypes from 'prop-types';

export default class ListItemChk extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    classSelected: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    modify: PropTypes.func.isRequired,
    selectedVirtuals: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.bool.isRequired,
    showFeaturesVirtuals: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: false,
    };
    this.state.value = props.value;
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    const { ...props } = this.props;
    const { value } = this.state;
    props.modify(props.id, !value);
    this.setState({ value: !value });
  }

  render() {
    const {
      name,
      description,
      classSelected,
      value,
      showFeaturesVirtuals,
    } = this.props;
    return (
      <div className={classSelected}>
        <Col md={12}>
          <FormGroup check>
            <Label className="chkLabelItem" check>
              <Input
                type="checkbox"
                checked={value}
                className="chkItem"
                disabled={showFeaturesVirtuals}
                onChange={this.onChange}
              />{' '}
              {name} {' ('}{description}{')'}
            </Label>
          </FormGroup>
        </Col>
      </div>
    );
  }
}
