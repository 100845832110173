import React, { PureComponent } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

export default class StatisticsGraph extends PureComponent {
  static propTypes = {
    stats: PropTypes.PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

  render() {
    const {
      stats,
    } = this.props;

    return (
      <Row>
        <Col md={12} className="graph">
          <LineChart
            width={500}
            height={400}
            data={stats}
            margin={{
              top: 0, right: 0, left: -50, bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </Col>
      </Row>
    );
  }
}
