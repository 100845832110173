import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import App from './PopupjsAddZone';
import SimpleReactValidator from '../../../shared/simple-react-validator';
import WizardApiCall from './WizardApiCall';

class WizardFormTwo extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    handleChangeEntity: PropTypes.func.isRequired,
    nextPageToCoupons: PropTypes.func.isRequired,
    campaignId: PropTypes.string.isRequired,
    fromEditor: PropTypes.bool,
  };

  static defaultProps = {
    fromEditor: false,
  };

  static modifyAudience(audience) {
    const caller = new WizardApiCall();
    let audM = '';
    try {
      audM = caller.modifyAudience(audience);
    } catch (e) {
      console.log(e);
      return;
    }
    if (audM === undefined) {
      return;
    }
    NotificationManager.success('Audiencia modificada correctamente.', ':');
  }

  static modifyZone(zone, regions) {
    let zoneM = '';
    const caller = new WizardApiCall();
    try {
      zoneM = caller.modifyZone(zone);
    } catch (e) {
      console.log(e);
      return;
    }
    if (zoneM === undefined) {
      return;
    }
    caller.modifyRegions(zone.id, regions);
    NotificationManager.success('Zona modificada correctamente.', ':');
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedZones: [],
      audience: {},
      zone: '',
      openModal: false,
      polygons: [],
      regions: [],
    };
    this.validator = new SimpleReactValidator({
      element: message => (
        <span style={{ color: 'red' }}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
    });
    this.createAudience = this.createAudience.bind(this);
    this.createRegion = this.createRegion.bind(this);
    this.createZone = this.createZone.bind(this);
    this.handleChangeEntity = this.handleChangeEntity.bind(this);
    this.handleChangeEntityOnModify = this.handleChangeEntityOnModify.bind(this);
  }

  handleChangeEntity(name, value) {
    const { handleChangeEntity } = this.props;
    this.setState({ [name]: value });
    handleChangeEntity(name, value);
  }

  handleChangeEntityOnModify(name, value) {
    const { selectedZones } = this.state;
    const zones = selectedZones;
    console.log(name === 'zone');
    if (name === 'zone') {
      for (let i = 0; i < selectedZones.length; i += 1) {
        if (selectedZones[i].zone.id === value.id) {
          zones[i].zone = value;
        }
      }
    }
    if (name === 'audience') {
      for (let i = 0; i < selectedZones.length; i += 1) {
        if (selectedZones[i].audience.id === value.id) {
          zones[i].audience = value;
        }
      }
    }
    this.handleChangeEntity('selectedZones', zones);
  }

  async createRegion(zoneId) {
    const { polygons } = this.state;
    polygons.map(
      async (polygonI) => {
        const pol = polygonI.map(c => ({ latitude: c.lat, longitude: c.lng }));
        const region = {
          id: 0,
          name: '',
          mapRegions: pol,
        };
        this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
        this.setState(previousState => ({
          regions: [...previousState.regions, region,
          ],
        }));
      },
    );
    const caller = new WizardApiCall();
    const { regions } = this.state;
    await caller.createRegions(regions, zoneId);
  }

  async createAudience() {
    const { audience } = this.state;
    const caller = new WizardApiCall();
    const aud = {
      genderId: App.decideGender(audience.publicSexM, audience.publicSexF, audience.publicSexNB),
      name: audience.name,
      olderThanAge: String(audience.olderThanAge.value),
      youngerThanAge: String(audience.youngerThanAge.value),
    };
    let audiencePromise = '';
    try {
      audiencePromise = await caller.createAudience(aud);
    } catch (e) {
      return;
    }
    if (audiencePromise === undefined) {
      return;
    }
    audience.id = audiencePromise.Content.AudienceId;
    this.setState({ audience });
  }

  async createZone() {
    const {
      zone, audience,
    } = this.state;
    const { campaignId, handleChangeEntity } = this.props;
    const caller = new WizardApiCall();
    let z = '';
    zone.audienceId = parseInt(audience.id, 10);
    zone.campaignId = parseInt(campaignId, 10);
    try {
      z = await caller.createZone(zone);
    } catch (e) {
      return;
    }
    if (z === undefined) {
      throw new Error(z.Content.Error.Message);
    }
    zone.id = parseInt(z.Content.ZoneId, 10);
    await this.createRegion(zone.id);
    const zonesState = {
      zone,
      audience,
    };
    this.setState(previousState => ({
      selectedZones: [...previousState.selectedZones, zonesState],
    }));
    const { selectedZones } = this.state;
    handleChangeEntity('selectedZones', selectedZones);
    NotificationManager.success('Zona creada correctamente.', '');
  }

  render() {
    const {
      handleSubmit, previousPage, campaignId, fromEditor, nextPageToCoupons,
    } = this.props;
    const {
      peopleFromResident, peopleFromNoResident, publicLanguage, publicInterest, openModal, selectedZones,
    } = this.state;
    console.log(selectedZones, peopleFromResident, peopleFromNoResident, publicLanguage, publicInterest);
    return (
      <form className="form form--horizontal wizard__form">
        <h3 className="wizard__title">Definición de zonas</h3>
        <App
          nextPageToCoupons={nextPageToCoupons}
          fromEditor={fromEditor}
          campaignId={campaignId}
          modifyZone={WizardFormTwo.modifyZone}
          modifyAudience={WizardFormTwo.modifyAudience}
          handleChangeEntityOnModify={this.handleChangeEntityOnModify}
          previousPage={previousPage}
          selectedZones={selectedZones}
          handleSubmit={handleSubmit}
          createAudience={this.createAudience}
          createZone={this.createZone}
          handleChangeEntity={this.handleChangeEntity}
          openModal={openModal}
          handleChangePolygons={this.handleChangeEntity}
        />
      </form>
    );
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
